import { AuthBaseService } from './../../../../../services/auth/auth-base.service';
import { AlertService } from './../../../../../services/alert.service';
import { Subject, Observable, forkJoin, Subscription } from 'rxjs';
import { Permission } from './../../../../models/permission.model';
import { User } from './../../../../models/user.model';
import { UserAccess } from './../../../../models/user-access.model';
import { UserAccessService } from './../../../../../services/user-access/user-access.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-user-update-modal',
  templateUrl: './user-update-modal.component.html',
  styleUrls: ['./user-update-modal.component.scss']
})
export class UserUpdateModalComponent implements OnInit {
  userAttrAccesses: UserAccess[] = [];

  selectablePermissionList: Permission[] = [];
  selectableStateList: [string[]] = [[]];

  dataSubmit: {
    permissao: Permission,
    uf: string
  }[] = [];

  userPermissions: UserAccess[] = [];
  canChange: boolean = true;

  submitBtnSubject: Subject<null> = new Subject();

  dataSubscription: Subscription = new Subscription();


  constructor(
    private dialogRef: MatDialogRef<UserUpdateModalComponent>,
    private userAccessService: UserAccessService,
    @Inject(MAT_DIALOG_DATA) public userData: User = new User(),
    private alertService: AlertService,
    private authBaseService: AuthBaseService
  ) { }

  isOwnUser(): void {
    this.authBaseService.getLoggedUser().subscribe((res: { user: User, userAccess: UserAccess[] }) => {
      this.canChange = res.user.id == this.userData.id ? false : true;
    })
  }

  ngOnInit(): void {
    this.getUserData();
    this.isOwnUser();
  }

  getUserData(): void {
    const permissoesQuePodeAtribuir = this.userAccessService.getAll(null, 'buscar-permissoes-que-eu-posso-atribuir');
    const userAccess = this.userAccessService.getById(this.userData.id, 'buscar-acessos-de');

    this.dataSubscription = forkJoin([permissoesQuePodeAtribuir, userAccess]).subscribe((res: [UserAccess[], UserAccess[]]) => {
      this.userAttrAccesses = res[0];
      this.userPermissions = res[1];
    })
  }

  addPermission(): void {
    this.userPermissions.push(new UserAccess());
  }

  removePermission(index: number): void {
    this.userPermissions.splice(index, 1);
  }

  submitBtn(): void {
    this.userPermissions.length > 0 ? this.submitBtnSubject.next(null) : this.submit(null);
  }

  submit(e: { userAccess: UserAccess, uf: string } | null): void {

    if (e) {
      this.dataSubmit.push({ permissao: e.userAccess.permissao, uf: e.uf });
    }

    if (this.dataSubmit.length === this.userPermissions.length) {

      this.userAccessService.create({ permissoesAcesso: this.dataSubmit, usuario: this.userData }).subscribe((res: { permissoesAcesso: any, usuario: User }) => {
        if (res) {
          this.alertService.success('Usuário atualizado com sucesso');
          this.dismiss(true);
        }
        this.dataSubmit = [];
      })
    }

  }

  dismiss(shouldUpdateList: boolean = false): void {
    this.dialogRef.close({ shouldUpdateList: shouldUpdateList });
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }
}
