import { Project } from './../../../../shared/models/project.model';
import { environment } from './../../../../../environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProjectVersionsModalComponent } from 'src/app/shared/components/modals/projects/project-versions-modal/project-versions-modal.component';
@Component({
  selector: 'app-project-card-public',
  templateUrl: './project-card-public.component.html',
  styleUrls: ['./project-card-public.component.scss']
})
export class ProjectCardPublicComponent implements OnInit {
  storageUrl = environment.api_url + 'public/';
  imageUrl: string = '';

  @Input() project: Project = new Project();
  @Output() shouldUpdateListEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private router: Router,
    private dialog: MatDialog
    ) {}

  ngOnInit(): void {
    this.imageUrl = environment.api_url + 'public/' + 'projetos/' + this.project.id + '/capa';
  }

  conhecerProjeto(): void {
    if(this.project.versoes && this.project.versoes.length > 1){
      this.openVersoesProjetoModal();
    } else{
      this.router.navigate([("/catalogo-projetos/details/" + this.project.id + "/0")]);
    }
  }

  useDefaultPic(): void {
    this.imageUrl = '/assets/img/img-padrao-projeto.png';
  }

  openVersoesProjetoModal(): void {
    let dialog = this.dialog.open(ProjectVersionsModalComponent, {
      data: { project: this.project, caller: this.router.url},
      height: "70vh",
      width: "50vw",
      panelClass: "no-padding-modal",
    });

    dialog?.afterClosed().subscribe((res: { shouldUpdateList: boolean}) => {
      if (res && res.shouldUpdateList) {
        this.shouldUpdateListEmitter.emit(res.shouldUpdateList);
      }
    })
  }
}
