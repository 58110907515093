import { Conteudo } from 'src/app/shared/models/conteudo.model';
import { Router } from '@angular/router';
import { ConteudoService } from './../home-page-area-publica/conteudo-home.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-vertente-info-public',
  templateUrl: './card-vertente-info-public.component.html',
  styleUrls: ['./card-vertente-info-public.component.scss']
})
export class CardVertenteInfoPublicComponent implements OnInit {

  static readonly vertentesIconAndColors:any[] = [
    {
      nome: 'Ambiente de Inovação',
      icon: '../../../../assets/homePage/ic_ambiente_inovacao.svg',
      color: '#D65906'
    },
    {
      nome: 'Programas e Ações',
      icon: '../../../../assets/homePage/ic_programas_e_acoes.svg',
      color: '#CC2336'
    },
    {
      nome: 'ICTI',
      icon: '../../../../assets/homePage/ic_icti.svg',
      color: '#D44C6C'
    },
    {
      nome: 'Políticas Públicas',
      icon: '../../../../assets/homePage/ic_politica_publica.svg',
      color: '#284395'
    },
    {
      nome: 'Capital',
      icon: '../../../../assets/homePage/ic_capital.svg',
      color: '#4C90B7'
    },
    {
      nome: 'Governança',
      icon: '../../../../assets/homePage/ic_governanca.svg',
      color: '#1D9E5D'
    }
  ];

  @Input() vertente: Conteudo;
  @Input() vertentes: Conteudo[] = [];

  constructor(
    private conteudoService: ConteudoService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if(this.vertente){
      this.conteudoService.filtrar({tipo: this.vertente.tipo}).subscribe(
        (outrosItens: Conteudo[]) => {
          this.vertentes = outrosItens.filter(item => item.id !== this.vertente.id);
        }, (error) => {}
      );
    }
  }

  findIconVertente(vertente: Conteudo): string{
    let url = "";
    CardVertenteInfoPublicComponent.vertentesIconAndColors.forEach(vertenteIcon => {
      if(vertenteIcon.nome.toUpperCase() === vertente.descricao.toUpperCase()){
        url = vertenteIcon.icon;
        return;
      }
    });
    return url;
  }

  findColorVertente(vertente: Conteudo): string{
    let url = "";
    CardVertenteInfoPublicComponent.vertentesIconAndColors.forEach(vertenteIcon => {
      if(vertenteIcon.nome.toUpperCase() == vertente.descricao.toUpperCase()){
        url = vertenteIcon.color;
        return;
      }
    });
    return url;
  }

  irParaSaibaMais(conteudo: Conteudo){

    if(conteudo.tipo !== ConteudoService.TIPO_VERTENTE){
      return;
    }

    if((this.router.url) !== "/saiba-mais"){
      this.router.navigate(["/saiba-mais"], {
        state: {conteudo: conteudo}
      });
    }else{
      this.router.navigate(["/"]).then(() => {
        this.router.navigate(["/saiba-mais"], {
          state: {conteudo: conteudo}
        });
      });
    }
  }
}
