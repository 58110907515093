import { AmeiToken } from './../models/amei-token.model';
import { AuthBaseService } from './../../services/auth/auth-base.service';

import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

    ameiTokenInterceptor: AmeiToken = new AmeiToken();

    constructor(
        private authBaseService: AuthBaseService,
    ) {
        this.ameiTokenInterceptor = this.authBaseService.ameiTokenInterceptor;
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        let headers: any = req.headers;

        if (this.authBaseService.getLocalStorageAmeiToken()) {
            headers = headers.append('Authorization', 'Bearer ' + this.authBaseService.getLocalStorageAmeiToken());

            if (this.useApplicationJson(headers) && !this.noContentType(headers)) {
                headers = headers.append('Content-Type', 'application/json;');
            }
        }

        const authReq = req.clone({
            headers
        });

        return next
            .handle(authReq)
            .pipe(
                map((event: HttpEvent<any>) => {
                    return event;
                }),
            );
    }

    useApplicationJson(header): boolean {
        if (header.get('Content-Type') !== null && (<string>header.get('Content-Type').includes('application/x-www-form-urlencoded'))) {
            return false;
        }
        return true;
    }

    noContentType(header): boolean {
        if (header.get('no-content-type')) {
            return true;
        }
        return false;
    }
}

