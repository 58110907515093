import { Router } from '@angular/router';
import { Project } from './../../../../models/project.model';
import { ProjectsService } from './../../../../../services/projects/projects.service';
import { environment } from './../../../../../../environments/environment';
import { AlertService } from './../../../../../services/alert.service';
import { UserAccessService } from './../../../../../services/user-access/user-access.service';
import { User } from './../../../../models/user.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-user-delete-modal',
  templateUrl: './user-delete-modal.component.html',
  styleUrls: ['./user-delete-modal.component.scss']
})
export class UserDeleteModalComponent implements OnInit {
  storageUrl = environment.api_url + 'public/';

  userProjects: Project[] = [];

  constructor(
    private dialogRef: MatDialogRef<UserDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private userAccessService: UserAccessService,
    private alertService: AlertService,
    private projectsService: ProjectsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getProjects();
  }

  getProjects(): void {
    this.projectsService.getById(this.user.id, 'buscar-projetos-por-responsavel').subscribe((res: Project[]) => {
      this.userProjects = res;
    })
  }

  delete(): void {
    this.userAccessService.create(null, 'inativar-usuario', this.user.id).subscribe((res: User) => {
      if (res) {
        this.alertService.success('Usuário removido com sucesso');
        this.dismiss(true);
      }
    })
  }

  toProjectDetail(id: number): void {
    this.router.navigate(['./projects/details/' + id + '/0']);
    this.dismiss();
  }

  dismiss(shouldUpdateList: boolean = false): void {
    this.dialogRef.close({ shouldUpdateList: shouldUpdateList });
  }
}
