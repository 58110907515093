import { RegiaoQtdProjetos } from './../../../../../shared/models/regiao-qtd-projetos.model';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-regiao-nordeste',
  templateUrl: './regiao-nordeste.component.html',
  styleUrls: ['./regiao-nordeste.component.scss']
})
export class RegiaoNordesteComponent implements OnInit {

  @Input() qtdProjetosRegNordeste: RegiaoQtdProjetos;
  @Input() isLogado: boolean = false;

  qtdProjetosMA: string;
  qtdProjetosPI: string;
  qtdProjetosBA: string;
  qtdProjetosCE: string;
  qtdProjetosRN: string;
  qtdProjetosPB: string;
  qtdProjetosPE: string;
  qtdProjetosAL: string;
  qtdProjetosSE: string;

  constructor(private router: Router) { }
  
  ngOnInit() {
    this.inicializarQtdProjEstado();
  }

  findQtdProjetosByEstado(uf: string){
    try{
      let qtd = this.qtdProjetosRegNordeste.projetosEstado.find(estado => estado.uf.toUpperCase() === uf.toUpperCase()).qtdProjetos;

      if(qtd > 0 && qtd < 10){
        return "0" + qtd;
      }else if(qtd >= 10){
        return qtd.toString();
      }
    }catch(error){}
    return "&nbsp;0";
  }

  inicializarQtdProjEstado(){
    this.qtdProjetosMA = this.findQtdProjetosByEstado("MA");
    this.qtdProjetosPI = this.findQtdProjetosByEstado("PI");
    this.qtdProjetosBA = this.findQtdProjetosByEstado("BA");
    this.qtdProjetosCE = this.findQtdProjetosByEstado("CE");
    this.qtdProjetosRN = this.findQtdProjetosByEstado("RN");
    this.qtdProjetosPB = this.findQtdProjetosByEstado("PB");
    this.qtdProjetosPE = this.findQtdProjetosByEstado("PE");
    this.qtdProjetosAL = this.findQtdProjetosByEstado("AL");
    this.qtdProjetosSE = this.findQtdProjetosByEstado("SE");
  }

  irParaCatalogo(uf: string, nome: string){
    let catalogo = this.isLogado? "/projects" : "/catalogo-projetos"
    this.router.navigate([catalogo], {
      state: {estadoSelecionadoCaller: {uf: uf, nome: nome}}
    });
  }
}
