import { Regional } from './../../../shared/models/regional.model';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "src/app/services/base/base.service";
import { UtilService } from "src/app/services/util.service";


@Injectable({
  providedIn: 'root'
})
export class RegionalService extends BaseService<any>{
  serviceName: string = 'v2/regionais';

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  deletarRegional(idRegional: number) {
    return this.http.delete(this.urlApi + this.serviceName + '/deletarRegional' + this.utilService.paramsToQueryString({ idRegional: idRegional }));
  }

  listarPublic(params: any) {
    return this.http.post<Regional[]>(this.urlApi + "public/" + this.serviceName, params);
  }

}
