import { SaibaMaisHomeComponent } from './home-page/saiba-mais-home/saiba-mais-home.component';
import { DetailsProjectPublicComponent } from './detalhes-projeto/details-project-public/details-project-public.component';
import { HomePageAreaPublicaComponent } from './home-page/home-page-area-publica/home-page-area-publica.component';
import { Routes, RouterModule } from '@angular/router';
import { CatalogoProjetosPublicComponent } from './catalogo-projetos/catalogo-projetos-public/catalogo-projetos-public.component';

const routes: Routes = [
  {
     path: '',
     component: HomePageAreaPublicaComponent
  },
  {
    path: 'catalogo-projetos',
    component: CatalogoProjetosPublicComponent
  },
  {
    path: 'catalogo-projetos/details/:id/:tabIndex',
    component: DetailsProjectPublicComponent,
  },
  {
    path: 'saiba-mais',
    component: SaibaMaisHomeComponent,
  },
];

//Register here the public routes to evict token validation
const publicRoutesList = ['/', '/catalogo-projetos', '/catalogo-projetos/details', '/saiba-mais'];

export const publicRoutes = publicRoutesList;
export const AreaPublicaRoutesModule = RouterModule.forChild(routes);
