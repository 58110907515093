import { MatDialog } from '@angular/material/dialog';
import { AuthBaseService } from 'src/app/services/auth/auth-base.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-user-noPermission-modal',
  templateUrl: './user-noPermission-modal.component.html',
  styleUrls: ['./user-noPermission-modal.component.scss']
})
export class UserNoPermissionModalComponent implements OnInit {

  constructor(
    private authService: AuthBaseService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    //
  }

  logout(): void {
    this.authService.logout();
    this.dialog.closeAll();

  }
}
