import { UtilService } from './../util.service';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseService<T> {
  protected serviceName: string = '';

  get urlApi(): string {
    return environment.api_url;
  }

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) { }

  // getLoggedUserPromise(): any {
  //   let http = this.http.get(this.urlApi + 'usuarios' + '/' + 'meus-acessos');
  //   return http.toPromise();
  // }

  getAll(params?: {}, ext?: string): Observable<any> {
    if (ext) {
      if (ext && params) {
        return this.http.get<T>(this.urlApi + this.serviceName + '/' + ext + this.utilService.paramsToQueryString(params));
      }
      return this.http.get<T>(this.urlApi + this.serviceName + '/' + ext);
    }

    if (!ext && params) {
      return this.http.get<T>(this.urlApi + this.serviceName + this.utilService.paramsToQueryString(params));
    }
    return this.http.get<T>(this.urlApi + this.serviceName);
  }

  getById(id: number, modelName?: string, suffix?: string) {
    if (!modelName) {
      if (suffix) {
        return this.http.get<T>(this.urlApi + this.serviceName + '/' + id + '/' + suffix);
      }
      return this.http.get<T>(this.urlApi + this.serviceName + '/' + id);

    }
    return this.http.get<T>(this.urlApi + this.serviceName + '/' + modelName + '/' + id);
  }

  create(payload: any, modelName?: string, id?: number | {}) {
    payload = payload !== null ? payload : {};

    if (modelName) {
      if (id && typeof id !== 'object') {
        return this.http.post(this.urlApi + this.serviceName + '/' + modelName + '/' + id, payload);
      }

      if (id && typeof id === 'object') {
        return this.http.post(this.urlApi + this.serviceName + '/' + modelName + this.utilService.paramsToQueryString(id), payload);
      }

      return this.http.post(this.urlApi + this.serviceName + '/' + modelName, payload);
    }

    if (!modelName) {
      if (id && typeof id === 'object') {
        return this.http.post(this.urlApi + this.serviceName + this.utilService.paramsToQueryString(id), payload);
      }

      if (id && typeof id !== 'object') {
        return this.http.post(this.urlApi + this.serviceName + '/' + id.toString(), payload);
      }
    }

    return this.http.post(this.urlApi + this.serviceName, payload);
  }

  delete(id: number, modelName?: string) {
    if (!modelName) {
      return this.http.delete<T>(this.urlApi + this.serviceName + '/' + id);
    }
    return this.http.delete<T>(this.urlApi + this.serviceName + '/' + modelName + '/' + id);
  }
}
