import { Router } from '@angular/router';
import { Conteudo } from '../../../../shared/models/conteudo.model';
import { Component, OnInit, Input } from '@angular/core';
import { ConteudoService } from '../home-page-area-publica/conteudo-home.service';

@Component({
  selector: 'app-card-timing-info-public',
  templateUrl: './card-timing-info-public.component.html',
  styleUrls: ['./card-timing-info-public.component.scss']
})
export class CardTimingInfoPublicComponent implements OnInit {

  static readonly timingsIcon:any[] = [
    {
      nome: "Escolha do Município",
      icon: "../../../../assets/homePage/ic_maps.svg"
    },
    {
      nome: "Reunião de kickoff",
      icon: "../../../../assets/homePage/ic_chat.svg"
    },
    {
      nome: "Vocações econômicas",
      icon: "../../../../assets/homePage/ic_dinheiro.svg"
    },
    {
      nome: "Potencial tecnológico",
      icon: "../../../../assets/homePage/ic_potencial_tecnologico.svg"
    },
    {
      nome: "Setores prioritários",
      icon: "../../../../assets/homePage/ic_setores_prioritarios.svg"
    },
    {
      nome: "Atores e entrevistas",
      icon: "../../../../assets/homePage/ic_atores_e_entrevistas.svg"
    },
    {
      nome: "Grau de maturidade",
      icon: "../../../../assets/homePage/ic_grau_maturidade.svg"
    },
    {
      nome: "Plano de intervenção",
      icon: "../../../../assets/homePage/ic_plano_intervencao.svg"
    },
    {
      nome: "Implementação",
      icon: "../../../../assets/homePage/ic_implementacao.svg"
    }
  ];


  @Input() timing: Conteudo;
  @Input() timings: Conteudo[] = [];

  constructor(
    private conteudoService: ConteudoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(this.timing){
      this.conteudoService.filtrar({tipo: this.timing.tipo}).subscribe(
        (outrosItens: Conteudo[]) => {
          this.timings = outrosItens.filter(item => item.id !== this.timing.id);
        }, (error) => {}
      );
    }
  }

  findIconTiming(timing: Conteudo): string{
    let url = "";
    CardTimingInfoPublicComponent.timingsIcon.forEach(timingItem => {
      if(timingItem.nome.toUpperCase() === timing.descricao.toUpperCase()){
        url = timingItem.icon;
        return;
      }
    });
    return url;
  }

  findIndexTiming(timing: Conteudo): number{
    for(let i = 0; i < CardTimingInfoPublicComponent.timingsIcon.length; i++){
      if(timing.descricao.toUpperCase() == CardTimingInfoPublicComponent.timingsIcon[i].nome.toUpperCase()){
        return i;
      }
    }
    return -1;
  }

  irParaSaibaMais(conteudo: Conteudo){

    if(conteudo.tipo !== ConteudoService.TIPO_TIMING){
      return;
    }

    if((this.router.url) !== "/saiba-mais"){
      this.router.navigate(["/saiba-mais"], {
        state: {conteudo: conteudo}
      });
    }else{
      this.router.navigate(["/"]).then(() => {
        this.router.navigate(["/saiba-mais"], {
          state: {conteudo: conteudo}
        });
      });
    }
  }
}
