import { Member } from './../../shared/models/member.model';
import { Observable } from 'rxjs';
import { User } from './../../shared/models/user.model';
import { UtilService } from './../util.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../base/base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MemberService extends BaseService<any>{
  serviceName: string = "membros-equipe-trabalho";

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  addMembers(payload: { list: User[] }, idProject: number): Observable<User[]> {
    return this.http.post<User[]>(this.urlApi +  this.serviceName + '/' + idProject.toString() + '/em-massa', payload);
  }

  removeMember(idProject: any, idUser: number): Observable<Member> {
    return this.http.delete<Member>(this.urlApi + this.serviceName + '/' + idProject + '/remover-usuario/' + idUser.toString());
  }

  listPublicByIdProjeto(idProject: any) {
    return this.http.get<Member[]>(this.urlApi + "public/" + this.serviceName + '/' + idProject);
  }
}
