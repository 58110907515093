//core
import { NgModule } from '@angular/core';

//router
import { RouterModule, Routes } from '@angular/router';

//routes
const routes: Routes = [

  {
   path: '',
   loadChildren: () => import('src/app/views/area-publica/area-publica.module').then(m => m.AreaPublicaModule),
  },
  {
    path: 'users',
    loadChildren: () => import('src/app/views/users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'projects',
    loadChildren: () => import('src/app/views/projects/projects.module').then(m => m.ProjectsModule),
  },
  {
    path: 'performance',
    loadChildren: () => import('src/app/views/performance/performance.module').then(m => m.PerformanceModule),
  },
  {
    path: 'regiao',
    loadChildren: () => import('src/app/views/regiao/regiao.module').then(m => m.RegiaoModule),
  },
  {
    path: 'administracao',
    loadChildren: () => import('src/app/views/administracao/administracao.module').then(m => m.AdministracaoModule),
  },
  {
    path: 'ajuda',
    loadChildren: () => import('src/app/views/solicitacao-ajuda/solicitacao-ajuda.module').then(m => m.AjudaModule),
  },
  {
    path: 'governanca/:id/:opcao',
    loadChildren: () => import('src/app/views/projects/projeto-governanca/projeto-governanca.module').then(m => m.ProjetoGovernancaModule),
  },
  {
    path: 'governanca/participante/:id/:opcao',
    loadChildren: () => import('src/app/views/projects/projeto-governanca/participante/participante-governanca.module').then(m => m.ParticipanteGovernancaModule),
  },
  {
    path: 'projects/details/:id/:tabIndex',
    loadChildren: () => import('src/app/views/projects/project-details/project-details.module').then(m => m.ProjectDetailsModule),
  },
  {
    path: 'projects/activities/:id',
    loadChildren: () => import('src/app/views/projects/project-activities/project-activities.module').then(m => m.ProjetoAtividadeModule),
  },
  {
    path: 'catalogo-projetos/details/:id/:tabIndex',
    loadChildren: () => import('src/app/views/area-publica/area-publica.module').then(m => m.AreaPublicaModule),
  },
  // {
  //   path: 'projects',
  //   children: [
  //     {
  //       path: 'details/:id',
  //       component: ProjectDetailsComponent
  //     }
  //   ]
  // },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

//ng-module
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { };
