import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "src/app/services/base/base.service";
import { UtilService } from "src/app/services/util.service";


@Injectable({
  providedIn: 'root'
})
export class CentroAjudaService extends BaseService<any>{
  serviceName: string = 'config-centro-ajuda';

  ajudaServiceName: string = 'ajuda';

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  getConfiguracao() {
    return this.http.get<any>(this.urlApi + this.serviceName);
  }

  verificaCentroAjudaAtivo() {
    return this.http.get<boolean>(this.urlApi + this.serviceName + '/' + 'verifica-ativo');
  }

  verificaCentroAjudaServidor(): Observable<boolean> {
    return this.http.get<boolean>(this.urlApi + this.serviceName + '/' + 'verifica-servidor');
  }

  salvarConfiguracao(emailDestino: string) {
    return this.http.post<any>(this.urlApi + this.serviceName + '/' + 'salvar', emailDestino);
  }

  solicitar(file: File, tipo: string, descricao: string ) {
    const uploadFormData: any = new FormData();
    if (file != null) {
      uploadFormData.append("file", file, file.name);
    }
    return this.http.post<any>(this.urlApi + this.ajudaServiceName + '/' + 'solicitar', uploadFormData, { params: { tipo: tipo, descricao: descricao },headers: new HttpHeaders({ 'no-content-type': 'true', 'accept': '*/*' }) });
  }

}
