import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() paginateConfig: {
    totalItems: number,
    itemsPerPage: number,
    currentPage: number
  } = {
      totalItems: 0,
      itemsPerPage: 0,
      currentPage: 1,
    }

  @Output() setCurrentPageEmitter: EventEmitter<number> = new EventEmitter<number>()

  totalPages: number = 0;
  totalPagesBK: number = 0;

  paginas: any[] = [];

  constructor(
  ) { }

  ngOnInit() {
  }

  ngDoCheck(): void {
    this.totalPages = Math.ceil(this.paginateConfig.totalItems / this.paginateConfig.itemsPerPage);
    this.botoesDePaginacao();
  }

  isFirstPage(): boolean {
    return this.paginateConfig.currentPage <= 1 ? true : false;
  }

  isLastPage(): boolean {
    return this.paginateConfig.currentPage >= this.totalPages ? true : false;
  }

  isCurrentPage(page: number): boolean {
    return this.getCurrent() === page ? true : false;
  }

  previous(): void {
    this.setCurrentPageEmitter.emit(this.paginateConfig.currentPage - 1);
  }

  next(): void {
    this.setCurrentPageEmitter.emit(this.paginateConfig.currentPage + 1);
  }

  setCurrent(page: number): void {
    setTimeout(() => {
      this.setCurrentPageEmitter.emit(page);
    }, 100);
  }

  getCurrent(): number {
    return this.paginateConfig.currentPage;
  }

  botoesDePaginacao(): void {
    this.paginas = [];
    let cutLoopBefore: boolean = false;

    for (let i = 1; i <= this.totalPages; i++) {
      if (i === 1 || (i > this.paginateConfig.currentPage - 4 && i < this.paginateConfig.currentPage + 4) && i !== this.totalPages) {
        this.paginas.push(i);
      }

      if (this.paginateConfig.currentPage > 5) {
        if (!cutLoopBefore) {
          this.paginas.push('...');
          cutLoopBefore = true;
        }
      }

      if (i === this.totalPages - 2 && this.totalPages - 4 > this.paginateConfig.currentPage) {
        this.paginas.push('...');
      }

      if (i === this.totalPages && this.totalPages > 1) {
        this.paginas.push(i);
        break;
      }
    }

  }

}
