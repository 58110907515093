import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-generic-delete',
  templateUrl: './generic-delete.component.html',
  styleUrls: ['./generic-delete.component.scss']
})
export class GenericDeleteComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<GenericDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string },
  ) { }

  ngOnInit(): void {
  }

  submit(){
    this.dismiss(true);
  }

  dismiss(shouldDelete: boolean = false): void {
    this.dialogRef.close({ shouldDelete: shouldDelete });
  }
}
