import { Potential } from './../../../../shared/models/potential.model';
import { Project } from './../../../../shared/models/project.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-potenciais-table-public',
  templateUrl: './potenciais-table-public.component.html',
  styleUrls: ['./potenciais-table-public.component.scss']
})
export class PotenciaisTablePublicComponent implements OnInit {

  @Input() submitProjectObj: Project = new Project(null);
  @Input() updateOnCheck: boolean = false;
  @Input() canChange: boolean = false;
  @Input() loadListTrigger: boolean = false;

  selectedPotential: Potential[] = [];

  shouldLoad: boolean = true;

  list: Potential[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.submitProjectObj.potenciais.length > 0 && this.shouldLoad) {
      this.list = this.submitProjectObj.potenciais;
      this.shouldLoad = false;
    }
  }

}
