import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AlertService } from './../../services/alert.service';
import { AuthBaseService } from './../../services/auth/auth-base.service';
import { UserPermissionService } from './../../services/user-permission/user-permission.service';

import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    runRefreshToken: boolean = true;
    openModalOnce: boolean = true;

    constructor(
        public authBaseService: AuthBaseService,
        public alertService: AlertService,
        private userPermission: UserPermissionService,
        private router: Router 
    ) {

    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<any> {

        return next.handle(request)
            .pipe(
                tap(data => {
                    if (data instanceof HttpResponse) {

                        if (data.body && data.body.error) {
                           // this.alertService.error(data.body.error);
                            throw new Error(data.body.error);
                        }
                        if (data.ok && data.status === 200 && request.body !== null && !data.url.includes("/public/")) {

                            if (this.runRefreshToken) {
                                this.runRefreshToken = false;

                                this.authBaseService.setAmeiToken(true);

                                setTimeout(() => {
                                    this.runRefreshToken = true;
                                }, 10000);
                            }

                        }
                    }
                }),
                catchError(err => {
                    switch (err.status) {
                        case 0:
                            if(err.url.includes("https://amei")){
                              this.alertService.error("Erro de comunicação com AMEI, por favor tente novamente. Se os erros persisirem entre em contato com o administrador.");
                            }else if(err.url.includes(environment.api_url)){
                              this.alertService.error("Erro ao se comunicar com API, por favor tente novamente. Se os erros persisirem entre em contato com o administrador.");
                            }
                            this.router.navigate(["/"]);
                            break;
                        case 400:
                            if (err.error && err.error.error_description){
                              if( err.error.error_description == 'Code not valid') {
                                this.alertService.error('Código retornado do AMEI inválido, por favor entre em contato com o administrador.');
                                this.authBaseService.logout();
                              }else if (err.error.error_description == 'Session not active') {
                               // this.alertService.error('Sessão AMEI inativa');
                              }
                            }
                            break;

                        case 401:
                          if (err.error !== null || err.message?.includes('permissão')) {
                            if (this.openModalOnce) {
                              this.userPermission.openModalNoPermission();
                              this.openModalOnce = false;
                            }
                          } else {
                              this.alertService.error('Sessão expirada');
                              this.authBaseService.logout();
                          }
                          break;
                        case 403:
                          if (err.error !== null || err.message?.includes('permissão')) {
                            if (this.openModalOnce) {
                              this.userPermission.openModalNoPermission();
                              this.openModalOnce = false;
                            }
                          } else {
                            this.alertService.error('Sessão expirada');
                            this.authBaseService.logout();
                          }
                          break;
                        case 415:

                            break;
                        case 500:
                            const error = err.error.message || err.statusText;
                            if (err.error.message && err.error.message.includes('permissão') && !err.error.message.includes('operação')) {
                                if (this.openModalOnce) {
                                  this.userPermission.openModalNoPermission();
                                  this.openModalOnce = false;
                                }
                            } else {
                              this.alertService.error("Erro ao processar solicitação, por favor tente novamente. Se os erros persisirem entre em contato com o administrador.");
                            }
                            break;
                    }
                    return throwError(err);
                }));
    }
}

