export class Solution {
    id: number;
    descricao: string;
    idSubvertente: number;
    idStatus: number;
    idEstrategia: number;
    idNivelDesenvolvimento: number;

    constructor(model?: Solution) {
        this.id = model?.id ? model.id : null;
        this.descricao = model?.descricao ? model.descricao : '';
        this.idSubvertente = model?.idSubvertente ? model.idSubvertente : null;
        this.idStatus = model?.idStatus ? model.idStatus : null;
        this.idEstrategia = model?.idEstrategia ? model.idEstrategia : null;
        this.idNivelDesenvolvimento = model?.idNivelDesenvolvimento ? model.idNivelDesenvolvimento : null;
    }
}