import { VertenteService } from 'src/app/services/vertente/vertente.service';
import { NotaSetor } from 'src/app/shared/models/nota-setor.model';
import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-detalhes-maturidade-setor-public',
  templateUrl: './detalhes-maturidade-setor-public.component.html',
  styleUrls: ['./detalhes-maturidade-setor-public.component.scss']
})
export class DetalhesMaturidadeSetorPublicComponent implements OnInit {

  innerWidth: number;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  @Input() notasPorSetor: NotaSetor[]
  constructor() { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  formatarDecimal(num: number){
    return num.toString().replace(".", ",");
  }

  formatarValor(num: number){
    if(num.toString().includes(".")){
      let indexDot = num.toString().indexOf(".");
      if(num.toString().length > indexDot+2){
        return num.toString().substring(0, indexDot+3);
      }
    }
    return num.toString();
  }

  isVertenteNotaUnica(vertente: string){
    return VertenteService.vertentesNotaUnica.includes(vertente.toUpperCase());
  }
}
