import * as moment from 'moment';

export class AcaoDTOs {
    comoViabilizar: string;
    concluido: boolean;
    dataFim: string | Date;
    dataInicio: string | Date;
    id: number | null;
    idPlanoSolucao: number | null;
    idPlanoSolucaoAdicional: number | null;
    indicadorImpacto: string;
    oqueFazer: string;
    quemEnvolver: string;
    resultadoEsperado: string;

    dataInicioConvertido?: string | null;
    dataFimConvertido?: string | null;
    isAtrasado?: boolean;

    constructor(obj?: AcaoDTOs) {
        this.comoViabilizar = obj ? obj.comoViabilizar : '';
        this.concluido = obj ? obj.concluido : false;
        this.dataFim = obj ? obj.dataFim : '';
        this.dataInicio = obj ? obj.dataInicio : '';
        this.id = obj ? obj.id : null;
        this.idPlanoSolucao = obj ? obj.idPlanoSolucao : null;
        this.idPlanoSolucaoAdicional = obj ? obj.idPlanoSolucaoAdicional : null;
        this.indicadorImpacto = obj ? obj.indicadorImpacto : '';
        this.oqueFazer = obj ? obj.oqueFazer : '';
        this.quemEnvolver = obj ? obj.quemEnvolver : '';
        this.resultadoEsperado = obj ? obj.resultadoEsperado : '';

        this.dataInicioConvertido = obj && obj.dataInicio ? moment(obj.dataInicio).utc().format("DD/MM/YYYY") : null;
        this.dataFimConvertido = obj && obj.dataFim ? moment(obj.dataFim).utc().format("DD/MM/YYYY") : null;
        this.isAtrasado = obj && obj.dataInicio && obj.dataFim && moment.duration(moment(obj.dataFim).utc().diff(moment().utc())).asDays() < 0 ? true : false;
    }
}