import { CardPassosImplementacaoComponent } from './home-page/card-passos-implementacao/card-passos-implementacao.component';
import { QtdProjetosRegioesBrComponent } from './home-page/mapa-brasil/qtd-projetos-regioes-br/qtd-projetos-regioes-br.component';
import { RegiaoSulComponent } from './home-page/mapa-brasil/regiao-sul/regiao-sul.component';
import { RegiaoSudesteComponent } from './home-page/mapa-brasil/regiao-sudeste/regiao-sudeste.component';
import { RegiaoCentroOesteComponent } from './home-page/mapa-brasil/regiao-centro-oeste/regiao-centro-oeste.component';
import { RegiaoNordesteComponent } from './home-page/mapa-brasil/regiao-nordeste/regiao-nordeste.component';
import { RegiaoNorteComponent } from './home-page/mapa-brasil/regiao-norte/regiao-norte.component';
import { MapaBrasilComponent } from './home-page/mapa-brasil/mapa-brasil.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { SpiderWebChartPublicComponent } from './detalhes-projeto/maturidade/spider-web-chart-public/spider-web-chart-public.component';
import { DetalhesMaturidadePublicComponent } from './detalhes-projeto/maturidade/detalhes-maturidade-public/detalhes-maturidade-public.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DetailsProjectPublicComponent } from './detalhes-projeto/details-project-public/details-project-public.component';
import { FooterAreaPublicaComponent } from './footer-area-publica/footer-area-publica.component';
import { HomePageAreaPublicaComponent } from './home-page/home-page-area-publica/home-page-area-publica.component';
import { RouterModule } from '@angular/router';
import { HeaderAreaPublicaComponent } from './header-area-publica/header-area-publica.component';
import { ProjectSharedModule } from './../../shared/modules/project-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectCardPublicComponent } from './catalogo-projetos/project-card-public/project-card-public.component';
import { CatalogoProjetosPublicComponent } from './catalogo-projetos/catalogo-projetos-public/catalogo-projetos-public.component';
import { AreaPublicaRoutesModule } from './area-publica.routing';
import { VocacaoTablePublicComponent } from './detalhes-projeto/vocacao-table-public/vocacao-table-public.component';
import { PotenciaisTablePublicComponent } from './detalhes-projeto/potenciais-table-public/potenciais-table-public.component';
import { ProjectDetailsAnalisePublicComponent } from './detalhes-projeto/project-details-analise-public/project-details-analise-public.component';
import { SetoresPrioritariosPublicComponent } from './detalhes-projeto/setores-prioritarios-public/setores-prioritarios-public.component';
import { PlanosAcaoProjectPublicComponent } from './detalhes-projeto/planos-acao-project-public/planos-acao-project-public.component';
import { SaibaMaisHomeComponent } from './home-page/saiba-mais-home/saiba-mais-home.component';
import { CardVertenteInfoPublicComponent } from './home-page/card-vertente-info-public/card-vertente-info-public.component';
import { CardTimingInfoPublicComponent } from './home-page/card-timing-info-public/card-timing-info-public.component';
import { DetalhesMaturidadeSetorPublicComponent } from './detalhes-projeto/maturidade/detalhes-maturidade-setor-public/detalhes-maturidade-setor-public.component';

@NgModule({
  imports: [
    CommonModule,
    AreaPublicaRoutesModule,
    ProjectSharedModule,
    RouterModule,
    CarouselModule,
    HighchartsChartModule
  ],
  declarations: [
    ProjectCardPublicComponent,
    CatalogoProjetosPublicComponent,
    HeaderAreaPublicaComponent,
    FooterAreaPublicaComponent,
    HomePageAreaPublicaComponent,
    DetailsProjectPublicComponent,
    VocacaoTablePublicComponent,
    PotenciaisTablePublicComponent,
    ProjectDetailsAnalisePublicComponent,
    SetoresPrioritariosPublicComponent,
    PlanosAcaoProjectPublicComponent,
    SaibaMaisHomeComponent,
    CardVertenteInfoPublicComponent,
    CardTimingInfoPublicComponent,
    MapaBrasilComponent,
    RegiaoNorteComponent,
    RegiaoNordesteComponent,
    RegiaoCentroOesteComponent,
    RegiaoSudesteComponent,
    RegiaoSulComponent,
    QtdProjetosRegioesBrComponent,
    DetalhesMaturidadePublicComponent,
    SpiderWebChartPublicComponent,
    DetalhesMaturidadeSetorPublicComponent,
    CardPassosImplementacaoComponent,
  ],
  exports: [
    HeaderAreaPublicaComponent,
    FooterAreaPublicaComponent
  ]
})
export class AreaPublicaModule { }
