import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sector } from './../../shared/models/sector.model';
import { BaseService } from './../base/base.service';
import { UtilService } from './../util.service';

@Injectable({
  providedIn: 'root'
})
export class SectorsService extends BaseService<any>{
  serviceName: string = "setores";

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  addSector(payload: {}, projectId: number) {
    return this.http.post(this.urlApi + this.serviceName + '/' + projectId + '/salvar', payload);
  }

  // updateSector(payload: {}, projectId: number) {
  //   return this.http.post(this.urlApi + this.serviceName + '/' + projectId + '/salvar-simples', payload);
  // }

  deleteSector(sectorId: number) {
    return this.http.delete(this.urlApi + this.serviceName + '/deletarSetorSegmento' + this.utilService.paramsToQueryString({ idSetorSegmento: sectorId }));
  }

  findPublicById(id: number){
    return this.http.get<Sector[]>(this.urlApi + "public/" + this.serviceName + '/' + id);
  }

  findByIdLight(id: number){
    return this.http.get<Sector[]>(this.urlApi + this.serviceName + "/light/" + id);
  }
  
}
