import { Subvertente } from './../../shared/models/subvertente.model';
import { UtilService } from './../util.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../base/base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubvertenteService extends BaseService<any>{
  serviceName: string = "subvertente";

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  listAllPublicByVertente(idVertente: number){
    return this.http.get<Subvertente[]>(this.urlApi + "public/" + this.serviceName + "/vertente/" + idVertente);
  }
}
