import { RegiaoQtdProjetos } from './../../../../../shared/models/regiao-qtd-projetos.model';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-regiao-sul',
  templateUrl: './regiao-sul.component.html',
  styleUrls: ['./regiao-sul.component.scss']
})
export class RegiaoSulComponent implements OnInit {

  @Input() qtdProjetosRegSul: RegiaoQtdProjetos;
  @Input() isLogado: boolean = false;

  qtdProjetosPR: string;
  qtdProjetosSC: string;
  qtdProjetosRS: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.inicializarQtdProjEstado();
  }

  findQtdProjetosByEstado(uf: string){
    try{
      let qtd = this.qtdProjetosRegSul.projetosEstado.find(estado => estado.uf.toUpperCase() === uf.toUpperCase()).qtdProjetos;

      if(qtd > 0 && qtd < 10){
        return "0" + qtd;
      }else if(qtd >= 10){
        return qtd.toString();
      }
    }catch(error){}
    return "&nbsp;0";
  }

  inicializarQtdProjEstado(){
    this.qtdProjetosPR = this.findQtdProjetosByEstado("PR");
    this.qtdProjetosSC = this.findQtdProjetosByEstado("SC");
    this.qtdProjetosRS = this.findQtdProjetosByEstado("RS");
  }

  irParaCatalogo(uf: string, nome: string){
    let catalogo = this.isLogado? "/projects" : "/catalogo-projetos"
    this.router.navigate([catalogo], {
      state: {estadoSelecionadoCaller: {uf: uf, nome: nome}}
    });
  }
}
