import { SectorsService } from './../../../../services/sectors/sectors.service';
import { Sector } from './../../../../shared/models/sector.model';
import { Member } from './../../../../shared/models/member.model';
import { Project } from './../../../../shared/models/project.model';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-setores-prioritarios-public',
  templateUrl: './setores-prioritarios-public.component.html',
  styleUrls: ['./setores-prioritarios-public.component.scss']
})
export class SetoresPrioritariosPublicComponent implements OnInit {
  @Input() project: Project = new Project();
  @Input() memberList: Member[] = [];

  dataSubscription: Subscription = new Subscription();

  sectorList: Sector[] = [];

  canChangeProject: boolean = false;

  isLoading: boolean = true;

  constructor(
    private sectorsService: SectorsService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.project.id > 0) {
      this.getSectors();
    }
  }

  getSectors(): void {
    const sectorSub = this.sectorsService.findPublicById(this.project.id).subscribe((res: Sector[]) => {
      this.sectorList = res;

      this.isLoading = false;
    })

    this.dataSubscription.add(sectorSub);
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }
}
