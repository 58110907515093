import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Analysis, LocalVocacacoes } from './../../shared/models/analysis.model';
import { BaseService } from './../base/base.service';
import { UtilService } from './../util.service';

@Injectable({
  providedIn: 'root'
})
export class AnalysisPotentialVocationService extends BaseService<any> {

  isLoading: boolean = true;

  serviceName: string = "analise-potencial-vocacao";
  vocations: LocalVocacacoes[] = [];
  analysis: Analysis = new Analysis();

  graphDataSubscription: Subscription = new Subscription();
  
  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  getPublicById(id: number){
    return this.http.get<Analysis>(this.urlApi + "public/" + this.serviceName + '/' + id);
  }

  getGraphData(id: number ): void {
    this.graphDataSubscription = this.getById(id).subscribe((res: Analysis) => {
      this.analysis = res;
      this.vocations = res.vocacoes;
      this.isLoading = false;
    })
  }

}
