import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-area-publica',
  templateUrl: './footer-area-publica.component.html',
  styleUrls: ['./footer-area-publica.component.scss']
})
export class FooterAreaPublicaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
