import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../../base/base.service";
import { UtilService } from "../../util.service";



@Injectable({
  providedIn: 'root'
})
export class EstadoService extends BaseService<any>{
  serviceName: string = 'v2/estados';

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  listarEstados() {
    return this.http.post(this.urlApi + "public/" + this.serviceName + '/' + "todos", {});
  }
}
