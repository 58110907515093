import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from './../environments/environment';
import { AlertService } from './services/alert.service';
import { AuthBaseService } from './services/auth/auth-base.service';
import { slideInAnimation } from './shared/animations';
import { publicRoutes } from './views/area-publica/area-publica.routing';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent {
  title = 'front-end';
  url: string;

  showHeader: boolean = false;
  dataSubscription: Subscription = new Subscription();
  showPrivateHeader: boolean = false;
  showPublicHeader: boolean = false;
  deveVerificarAMEI: boolean = false;

  constructor(
    private authBaseService: AuthBaseService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.buscarDadosLogin();
  }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.showHeader = this.shouldShowHeader(res);

        //pegar a rota atual e verificar se não se trata de uma área publica
        this.url = this.router.url;

        if(this.url.startsWith("/?session_state") || this.url.startsWith("/?redirect_uri")){
          this.showPublicHeader = true;
          this.deveVerificarAMEI = false;
          this.alertService.warning("Processando retorno do AMEI, aguarde...");
          return;
        }else{
          this.deveVerificarAMEI = true;
        }

        if(this.url.startsWith("/?")){
          return;
        }

        let token = this.authBaseService.getLocalStorageAmeiToken();
        let isPublicRoute = this.isPublicRoute(this.url);

        if(this.url == "/"){
          isPublicRoute = true;
        }

        if(token){
          this.showPrivateHeader = true;
          this.showPublicHeader = false;
        }else{
          this.showPublicHeader = true;
          this.showPrivateHeader = false;
        }

        if(!isPublicRoute){
          //this.showPrivateHeader = true;
          //this.showPublicHeader = false;
          this.authBaseService.setUserData();
        }else{
          //this.showPublicHeader = true;
          // this.showPrivateHeader = false;
        }
      }
    })
  }

  shouldShowHeader(res: NavigationEnd): boolean {
    return (res.urlAfterRedirects.length < 4 || res.urlAfterRedirects.includes('session_state') || res.urlAfterRedirects.includes('redirect_uri')) ? false : true;
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }

  buscarDadosLogin(){
    this.authBaseService.getEnvironmentVariables(environment.api_url).subscribe((res: { ameiLogin: string, realm: string, resource: string, ameiMinhaConta: string, clarityId: string }) => {
      this.authBaseService.setEnvironmentVariables(res.ameiLogin, res.realm, res.resource, res.ameiMinhaConta, res.clarityId);
      this.authBaseService.setAmeiConfig();
      
    });
  }

  isPublicRoute(url: string){
    let isPublic = false;
    for(let i = 0; i < publicRoutes.length; i++){
      let route = publicRoutes[i];
      isPublic = route.includes(url) ||  (url.startsWith(route) && route !== "/");

      if(isPublic){
        break;
      }
    }
    return isPublic;
  }
}
