import { RegioesEstadosQtdProjetosBR } from './../../../../shared/models/regioes-estados-qtd-projetos-br.model';
import { Subject } from 'rxjs';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mapa-brasil',
  templateUrl: './mapa-brasil.component.html',
  styleUrls: ['./mapa-brasil.component.scss']
})
export class MapaBrasilComponent implements OnInit {
  isZooming: boolean = false;
  @Output() emitIsZooming = new EventEmitter<boolean>();
  @Input() changingZoom: Subject<boolean> = new Subject<boolean>();
  @Input() isLogado: boolean = false;

  regiaoAmpliada: string;
  @Input() totalProjRegBr: RegioesEstadosQtdProjetosBR;
  constructor() { }

  ngOnInit(): void {
    this.inicializarQtdPorRegioes();

    this.changingZoom.subscribe(v => { 
      this.alterarZoomMapa("NORTE");
    });
  }

  qtdProjNorte: string;
  qtdProjNordeste: string;
  qtdProjSudeste: string;
  qtdProjSul: string;
  qtdProjCentroOeste: string;
  
  zoomInRegiao(regiao: string){
    let estados = document.getElementsByClassName("estado");
  
    for(let i = 0; i < estados.length; i++){
      let element = estados[i];
      if(element.getAttribute("regiao") == regiao){
        element.classList.add("regiao-zooming");
      }else{
        element.classList.remove("regiao-zooming");
      }
    }
  }

  zoomOutRegiao(){
    let estados = document.getElementsByClassName("estado");

    for(let i = 0; i < estados.length; i++){
      let element = estados[i];
      element.classList.remove("regiao-zooming");
    }
  }

  alterarZoomMapa(regiao: string){

    if(!this.isZooming){
      this.zoomInRegiao(regiao); 
      this.regiaoAmpliada = regiao;
    }else{
      this.zoomOutRegiao()
      this.regiaoAmpliada = null;
    }
    this.isZooming = !this.isZooming;
    this.emitIsZooming.emit(this.isZooming);
  }

  buscarTotalPorRegiao(regiao: string){
    try{
      let qtd = this.totalProjRegBr.regioesProjCount.find(reg => reg.regiao.toUpperCase() == regiao.toUpperCase()).totalProjetos;
      if(qtd > 0 && qtd <= 9){
        return "0" + qtd;
      }else if(qtd >= 10){
        return qtd.toString();
      }
    }catch(err){
    }
    return "0";
  }

  buscarRegiao(regiao: string){
    return this.totalProjRegBr.regioesProjCount.find(reg => reg.regiao.toUpperCase() == regiao.toUpperCase());
  }

  inicializarQtdPorRegioes(){
    this.qtdProjNorte = this.buscarTotalPorRegiao("NORTE");
    this.qtdProjNordeste = this.buscarTotalPorRegiao("NORDESTE");
    this.qtdProjSudeste = this.buscarTotalPorRegiao("SUDESTE");
    this.qtdProjSul = this.buscarTotalPorRegiao("SUL");
    this.qtdProjCentroOeste = this.buscarTotalPorRegiao("CENTRO-OESTE");
  }

}
