import { AmeiConfig } from './../../../shared/models/amei-config.model';
import { AuthBaseService } from 'src/app/services/auth/auth-base.service';
import { UtilService } from './../../../services/util.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { ConteudoService } from '../home-page/home-page-area-publica/conteudo-home.service';

@Component({
  selector: 'app-header-area-publica',
  templateUrl: './header-area-publica.component.html',
  styleUrls: ['./header-area-publica.component.scss']
})
export class HeaderAreaPublicaComponent implements OnInit {

  ameiConfig: AmeiConfig = new AmeiConfig();
  @Input() url: string;
  @Input() verificarAMEI: boolean = true;

  constructor(
    private router: Router,
    private utilService: UtilService,
    private authBaseService: AuthBaseService,
  ) { }

  ngOnInit() {
    if(this.verificarAMEI){
      this.authBaseService.getAmeiConfig().subscribe((res: AmeiConfig) => {
        if (res.authorization_endpoint) {
          this.ameiConfig = res;
          this.setToken();
        }
      }, (err) => {
      });
    }
  }

  setToken(): void {
    if (window.location.search.length > 2) {
      this.authBaseService.setAmeiToken();
    }
  }

  irParaHome(){
    if(this.url !== "/"){
      this.router.navigate(["/"]);
    }
  }

  login(): void {
    window.location.href = this.ameiConfig.authorization_endpoint + this.utilService.paramsToQueryString(this.authBaseService.config);
  }

  verEcossistema() {
    if(this.url !== "/"){
      this.router.navigate(["/"],  {
        state: {scroll: {
          tipo: ConteudoService.TIPO_VERTENTE,
          id: "vertentes-container"
        }}
      });
    }else{
      let element = document.getElementById("vertentes-container")
      if(element){
        element.scrollIntoView({behavior: 'smooth'});
      }
    }
  }

}
