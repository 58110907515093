import { UtilService } from './../../../services/util.service';
import { environment } from './../../../../environments/environment';
import { User } from './../../models/user.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile-card',
  templateUrl: './user-profile-card.component.html',
  styleUrls: ['./user-profile-card.component.scss']
})
export class UserProfileCardComponent implements OnInit {
  storageUrl = environment.api_url + 'public/';

  @Input() userData: User = new User();
  @Input() showPermissionList: boolean = false;

  constructor(
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    // this.userData.sub = this.storageUrl + 'usuarios/' + this.userData.sub + '/avatar';
  }

  useDefaultPic(): void {
    this.userData.sub = this.utilService.useDefaultProfilePic();
  }
}
