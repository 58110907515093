import { Conteudo } from './../../../../shared/models/conteudo.model';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { ConteudoService } from '../home-page-area-publica/conteudo-home.service';

@Component({
  selector: 'app-card-passos-implementacao',
  templateUrl: './card-passos-implementacao.component.html',
  styleUrls: ['./card-passos-implementacao.component.scss']
})
export class CardPassosImplementacaoComponent implements OnInit {

  static readonly passosIcon:any[] = [
    {
      nome: "Caracterização do Ecossistema de Inovação",
      icon: "../../../../../assets/homePage/etapa_1_metodologia.jpg"
    },
    {
      nome: "Nível de Maturidade do Ecossistema de Inovação",
      icon: "../../../../../assets/homePage/etapa_2_metodologia.jpg"
    },
    {
      nome: "Identificação dos Pontos de Intervenção",
      icon: "../../../../../assets/homePage/etapa_3_metodologia.jpg"
    },
    {
      nome: "Plano de Intervenção",
      icon: "../../../../../assets/homePage/etapa_4_metodologia.jpg"
    },
    {
      nome: "Organização para Intervenção",
      icon: "../../../../../assets/homePage/etapa_5_metodologia.jpg"
    },
    {
      nome: "Atuação Conjunta dos Atores",
      icon: "../../../../../assets/homePage/etapa_6_metodologia.jpg"
    },
    {
      nome: "Monitoramento do Ecossistema",
      icon: "../../../../../assets/homePage/etapa_7_metodologia.jpg"
    },
  ];


  @Input() passo: Conteudo;
  @Input() passos: Conteudo[] = [];

  constructor(
    private conteudoService: ConteudoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(this.passo){
      this.conteudoService.filtrar({tipo: this.passo.tipo}).subscribe(
        (outrosItens: Conteudo[]) => {
          this.passos = outrosItens.filter(item => item.id !== this.passo.id);
        }, (error) => {}
      );
    }
  }

  findIconPasso(passo: Conteudo): string{
    let url = "";
    CardPassosImplementacaoComponent.passosIcon.forEach(passoItem => {
      if(passoItem.nome.toUpperCase() === passo.descricao.toUpperCase()){
        url = passoItem.icon;
        return;
      }
    });
    return url;
  }

  irParaSaibaMais(conteudo: Conteudo){

    if(conteudo.tipo !== ConteudoService.TIPO_IMPLANTACAO){
      return;
    }

    if((this.router.url) !== "/saiba-mais"){
      this.router.navigate(["/saiba-mais"], {
        state: {conteudo: conteudo}
      });
    }else{
      this.router.navigate(["/"]).then(() => {
        this.router.navigate(["/saiba-mais"], {
          state: {conteudo: conteudo}
        });
      });
    }
  }
}
