import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MaturidadeService } from './../../../../../services/maturidade/maturidade.service';
import { VertenteService } from './../../../../../services/vertente/vertente.service';
import { NotaSetor } from './../../../../../shared/models/nota-setor.model';
import { NotaVertente } from './../../../../../shared/models/nota-vertente.model';
import { Project } from './../../../../../shared/models/project.model';

@Component({
  selector: 'app-detalhes-maturidade-public',
  templateUrl: './detalhes-maturidade-public.component.html',
  styleUrls: ['./detalhes-maturidade-public.component.scss']
})
export class DetalhesMaturidadePublicComponent implements OnInit {
  innerWidth: number;

  isLoadingNotasVertente: boolean = true;
  isLoadingNotasSetor: boolean = true;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  @Input() project: Project;
  notasVertente: NotaVertente;
  notasSetor: NotaSetor[];

  constructor(
    private maturidadeService: MaturidadeService
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  ngOnChanges(changes){
    this.project = changes.project.currentValue;

    if(this.project.id > 0){
      this.maturidadeService.buscarNotaFinalVertente(this.project.id).subscribe(
        (notasVertente: NotaVertente) => {
          this.notasVertente = notasVertente;
          this.isLoadingNotasVertente = false;
        }, (error) => {
          this.isLoadingNotasVertente = false;
        }
      );

      this.maturidadeService.buscarNotaPorSetor(this.project.id).subscribe(
        (notasSetor: NotaSetor[]) => {
          this.notasSetor = notasSetor;
          this.isLoadingNotasSetor = false;
        }, (error) => {
          this.isLoadingNotasSetor = false;
        }
      );
    }
  }

  formatarDecimal(num: number){
    return num.toString().replace(".", ",");
  }

  formatarValor(num: number){
    if(num.toString().includes(".")){
      let indexDot = num.toString().indexOf(".");
      if(num.toString().length > indexDot+2){
        return num.toString().substring(0, indexDot+3);
      }
    }
    return num.toString();
  }

  isVertenteNotaUnica(vertente: string){
    return VertenteService.vertentesNotaUnica.includes(vertente.toUpperCase());
  }
}
