import { UserAccess } from './user-access.model';
export class User {
    id: number;
    nome: string;
    email: string;
    cpf: string | null;
    displayName: string;
    permissoes: UserAccess[];
    pathAvatar: string | null;
    sub: string;

    isSelected?: boolean = false;

    constructor() {
        this.id = 0;
        this.nome = '';
        this.email = '';
        this.cpf = '';
        this.displayName = '';
        this.permissoes = [];
        this.pathAvatar = '';
        this.sub = '';
    }
}