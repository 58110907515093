import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Conteudo } from 'src/app/shared/models/conteudo.model';
import { RegioesEstadosQtdProjetosBR } from './../../../../shared/models/regioes-estados-qtd-projetos-br.model';
import { AmeiConfig } from './../../../../shared/models/amei-config.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthBaseService } from 'src/app/services/auth/auth-base.service';
import { Subject } from 'rxjs';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { Router } from '@angular/router';
import { ConteudoService } from './conteudo-home.service';
import { Component, OnInit, } from '@angular/core';

@Component({
  selector: 'app-home-page-area-publica',
  templateUrl: './home-page-area-publica.component.html',
  styleUrls: ['./home-page-area-publica.component.scss'],
})
export class HomePageAreaPublicaComponent implements OnInit {
  vertentes: Conteudo[] = [];
  passosImplantacao: Conteudo[] = [];
  timings: Conteudo[] = [];
  sobreSistema: Conteudo;
  sobreSistemaDescricao: SafeHtml;

  totalProjRegBr: RegioesEstadosQtdProjetosBR;
  isLoadingMapData: boolean = false;
  isZooming: boolean = false;
  changingZoom: Subject<boolean> = new Subject();
  ameiConfig: AmeiConfig = new AmeiConfig();
  isLogado: boolean = false;

  scrollToItem: any;
  constructor(
    private conteudoService: ConteudoService,
    private router: Router,
    private projectService: ProjectsService,
    private authBaseService: AuthBaseService,
    private alertService: AlertService,
    private _sanitizer: DomSanitizer
    ) {
      this.scrollToItem = this.router.getCurrentNavigation()?.extras?.state?.scroll;
     }

  ngOnInit() {
    this.carregarPassosImplantacao();
    this.carregarVertentes();
    this.carregarTimings();
    this.carregarSobreSistema();
    this.carregarQtdProjetosBR();

    this.authBaseService.getAmeiConfig().subscribe((res: AmeiConfig) => {
      if (res.authorization_endpoint) {
        this.ameiConfig = res;
        this.setToken();
      }

      let token = this.authBaseService.getLocalStorageAmeiToken();
      if(token){
        this.isLogado = true;
      }
    }, (err) => {
      this.alertService.error('Problemas na requisição do AMEI');
    });
  }

  setToken(): void {
    if (window.location.search.length > 2) {
      this.authBaseService.setAmeiToken();
    }
  }

  carregarVertentes() {
    this.conteudoService.filtrar({tipo: ConteudoService.TIPO_VERTENTE}).subscribe(
      (vertentes) => {
        this.vertentes = vertentes;
        if(this.scrollToItem?.tipo == ConteudoService.TIPO_VERTENTE){
          setTimeout(() => this.scroll(this.scrollToItem), 500);
        }
      }, (error) => {
        if(this.scrollToItem?.tipo == ConteudoService.TIPO_VERTENTE){
          setTimeout(() => this.scroll(this.scrollToItem), 500);
        }
       }
    );
  }

  carregarTimings(){
    this.conteudoService.filtrar({tipo: ConteudoService.TIPO_TIMING}).subscribe(
      (timings) => {
        this.timings = timings;
        if(this.scrollToItem?.tipo == ConteudoService.TIPO_TIMING){
          setTimeout(() => this.scroll(this.scrollToItem), 500);
        }
      }, (error) => {
        if(this.scrollToItem?.tipo == ConteudoService.TIPO_TIMING){
          setTimeout(() => this.scroll(this.scrollToItem), 500);
        }
       }
    );
  }

  carregarSobreSistema(){
    this.conteudoService.filtrar({tipo: ConteudoService.TIPO_SOBRE_SISTEMA}).subscribe(
      (sobreSistema) => {
        this.sobreSistema = sobreSistema[0];
        this.sobreSistemaDescricao = this._sanitizer.bypassSecurityTrustHtml(this.sobreSistema.dscPublicado.replace("\n", "<br/>"));
      }, (error) => {
       }
    );
  }

  carregarPassosImplantacao(){
    this.conteudoService.filtrar({tipo: ConteudoService.TIPO_IMPLANTACAO}).subscribe(
      (passosImplantacao) => {
        this.passosImplantacao = passosImplantacao;
        this.passosImplantacao.sort((a1, a2) => a1.id - a2.id);
        if(this.scrollToItem?.tipo == ConteudoService.TIPO_IMPLANTACAO){
          setTimeout(() => this.scroll(this.scrollToItem), 500);
        }
      }, (error) => {
        if(this.scrollToItem?.tipo == ConteudoService.TIPO_IMPLANTACAO){
          setTimeout(() => this.scroll(this.scrollToItem), 500);
        }
      }
    );
  }

  scroll(scrollTo: any) {
    let el = document.getElementById(scrollTo.id);
    if(el){
      el.scrollIntoView({behavior: 'smooth'});
    }
  }

  scrollToMapa(){
    this.scroll({tipo: "mapa", id: "container-mapa"});
  }

  carregarQtdProjetosBR(){
    this.isLoadingMapData = true;
    this.projectService.listAllByRegiaoBR().subscribe(
      (res: RegioesEstadosQtdProjetosBR) => {
        this.totalProjRegBr = res;
        this.isLoadingMapData = false;

        if(this.scrollToItem){
          setTimeout(() => this.scroll(this.scrollToItem), 500);
        }
      }, (error) => {
        this.isLoadingMapData = false;
        if(this.scrollToItem){
          setTimeout(() => this.scroll(this.scrollToItem), 500);
        }
      }
    );
  }

  callZoomOut(){
    this.changingZoom.next(true);
  }

  changeZoomIntern(zoom: boolean){
    this.isZooming = zoom;
  }

  irParaSaibaMaisPassosImplantacao(){
    if(!this.passosImplantacao){
      return;
    }

    let conteudo = this.passosImplantacao[0];

    if((this.router.url) !== "/saiba-mais"){
      this.router.navigate(["/saiba-mais"], {
        state: {conteudo: conteudo}
      });
    }else{
      this.router.navigate(["/"]).then(() => {
        this.router.navigate(["/saiba-mais"], {
          state: {conteudo: conteudo}
        });
      });
    }
  }
}
