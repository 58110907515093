import { Vocation } from './../../../../shared/models/vocacao.model';
import { Potential } from './../../../../shared/models/potential.model';
import { CalculatedVocationService } from './../../../../services/calculated-vocation/calculated-vocation.service';
import { CalculatedPotencialService } from './../../../../services/calculated-potential/calculated-potencial.service';
import { AlertService } from './../../../../services/alert.service';
import { UtilService } from './../../../../services/util.service';
import { MemberService } from './../../../../services/member/member.service';
import { ProjectsService } from './../../../../services/projects/projects.service';
import { User } from './../../../../shared/models/user.model';
import { Member } from './../../../../shared/models/member.model';
import { Project } from './../../../../shared/models/project.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription, first, forkJoin } from 'rxjs';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-project-public',
  templateUrl: './details-project-public.component.html',
  styleUrls: ['./details-project-public.component.scss']
})
export class DetailsProjectPublicComponent implements OnInit {

  getProjectPotential() {
    throw new Error('Method not implemented.');
  }
  getProjectVocation() {
    throw new Error('Method not implemented.');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mostraHtml = false;
    setTimeout(() => {
    this.mostraHtml = true;
    }, 1000);
  }

  setaVerdadeiro(){
    this.mostraHtml = true;
  }

  mostraHtml: boolean = true;

  storageUrl = environment.api_url + 'public/';

  selected = new FormControl(0);

  id: number = 0;
  tabIndex: number = 0;

  project: Project = new Project();
  memberList: Member[] = [];

  loadListTrigger: boolean = false;

  userDataSubscription: Subscription = new Subscription();
  memberListSubscription: Subscription = new Subscription();
  projectDataSubscription: Subscription = new Subscription();

  selectedMemberList: User[] = [];
  triggerChangesInChildComponent: boolean = false;

  urlCapa: string = this.useDefaultPic();
  defCapa: string = this.useDefaultPic();

  showMoreOption: boolean = false;
  descricaoProjetoOverflow: boolean = false;
  exibindoDescricaoCompletaProjeto: boolean = false;

  isLoadingProject: boolean = false;
  isLoadingVocacao: boolean = false;
  isLoadingPotencial: boolean = false;

  conteudoHTML: SafeHtml;

  constructor(
    private projectsService: ProjectsService,
    private activatedRoute: ActivatedRoute,
    private memberService: MemberService,
    private utilService: UtilService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private calculatedPotencialService: CalculatedPotencialService,
    private calculatedVocationService: CalculatedVocationService,
    private router: Router,
    private projService: ProjectsService,
    private _sanitizer: DomSanitizer
  ) {
    this.isLoadingProject = true;
    this.id = this.activatedRoute.snapshot.params.id;
    projService.pesquisarPorIdPublic(this.id).subscribe(
      (project: Project) => {
        this.project = project;
        if(this.project && this.project.resumoHtml){
          this.project.resumoHtml = this.formatHtmlString(this.project.resumoHtml);
          this.conteudoHTML = this._sanitizer.bypassSecurityTrustHtml(this.project.resumoHtml);
        }

        this.urlCapa = environment.api_url + 'public/projetos/' + this.id + '/capa'
        this.isLoadingProject = false;
      }, (error) => {
        this.isLoadingProject = false;
      }
    );

    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.selected.setValue(this.activatedRoute.snapshot.params.tabIndex);
      }
    });
  }

  ngOnInit(): void {
    this.getProjectData();
    this.getMemberList();
  }



  getProjectData(): void {
    this.isLoadingProject = true;
    this.projectDataSubscription = this.projectsService.pesquisarPorIdPublic(this.id).subscribe(
      (projeto: Project) => {
        this.isLoadingProject = false;
        if (projeto) {
          this.project = projeto;

          if(this.project && this.project.resumoHtml){
            this.project.resumoHtml = this.formatHtmlString(this.project.resumoHtml);
            this.conteudoHTML = this._sanitizer.bypassSecurityTrustHtml(this.project.resumoHtml);
          }

          if(this.project && this.project.resumoHtml?.length > 1500){
            this.descricaoProjetoOverflow = true;
            this.exibindoDescricaoCompletaProjeto = false;
          }
          this.getPotentialVocation();
        }
      }, (error) => {
        this.isLoadingProject = false;
      }
    );
  }

  expandirDescricaoProjeto(){
    this.exibindoDescricaoCompletaProjeto = true;
  }

  recolherDescricaoProjeto(){
    this.exibindoDescricaoCompletaProjeto = false;
  }

  getPotentialVocation(): void {
    this.isLoadingPotencial = true;
    this.isLoadingVocacao = true;

    const potential = this.calculatedPotencialService.getByIdPublic(this.id).pipe(first());
    const vocation = this.calculatedVocationService.getByIdPublic(this.id).pipe(first());

    forkJoin([potential, vocation]).subscribe((res: [Potential[], Vocation[]]) => {
      this.project.potenciais = res[0];
      this.project.vocacoes = res[1];

      this.isLoadingVocacao = false;
      this.isLoadingPotencial = false;

      this.loadListTrigger = true;
    });
  }

  getMemberList(): void {
    this.memberListSubscription?.unsubscribe();
    this.memberListSubscription = this.memberService.listPublicByIdProjeto(this.id).subscribe((res: Member[]) => {
      this.memberList = res;

      for (let i = 0; i < this.memberList.length; i++) {
        if(this.memberList[i].sub && this.memberList[i].sub != "") {
          this.memberList[i].sub = this.storageUrl + 'usuarios/' + this.memberList[i].sub + 'avatar';
        }
      }

    })
  }

  mudaAba(index: number): void {
    this.router.navigate(['./catalogo-projetos/details/' + this.id + '/' + index]);
  }

  voltarCatalogo(){
    this.router.navigate(['./catalogo-projetos/']);
  }

  ngOnDestroy(): void {
    this.userDataSubscription?.unsubscribe();
    this.projectDataSubscription?.unsubscribe();
    this.memberListSubscription?.unsubscribe();
  }

  useDefaultPic() {
    return '/assets/img/img-padrao-projeto.png';
  }

  formatHtmlString(htmlStr: string){
    return htmlStr.replace('\"', '"');
  }

}
