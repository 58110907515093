import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from './../../../../../services/alert.service';
import { ProjectsService } from './../../../../../services/projects/projects.service';
import { Member } from './../../../../models/member.model';
import { Project } from './../../../../models/project.model';

@Component({
  selector: 'app-project-update-modal',
  templateUrl: './project-update-modal.component.html',
  styleUrls: ['./project-update-modal.component.scss']
})
export class ProjectUpdateModalComponent implements OnInit {
  component: { project: Project; memberList: Member[]; };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { project: Project, memberList: Member[], isDuplicar: boolean },
    private dialogRef: MatDialogRef<ProjectUpdateModalComponent>,
    private projectsService: ProjectsService,
    private alertService: AlertService
  ) { }

  nomeProjetoAntigo: string;

  ngOnInit(): void {
    this.nomeProjetoAntigo = this.data?.project?.descricao;
  }

  submit(e: Project) {
    let obj: Project = new Project();

    obj.id = this.data.project.id;
    obj.descricao = e.descricao;
    obj.responsavel = e.responsavel;
    obj.dataAnoref = e.dataAnoref;
    obj.resumoHtml = e.resumoHtml;
    obj.descricaoAntiga = this.nomeProjetoAntigo;

    let filterObj = {
      campoAberto: obj.descricao,
      dataAnoRef: obj.dataAnoref,
      municipios: this.data.project.municipios,
      estados: this.data.project.estados
    }

   this.projectsService.create(filterObj, "list-repetidos").subscribe((res: Project[]) => {
          if (res && res.length != 0) {
            if (res[0].id != obj.id) {
              this.alertService.error('Já existe um projeto cadastrado para este ano de referência contendo o mesmo agrupamento de municípios selecionados');
              return;
            }
          }  
    

          if (e.imageFile !== null) {
            if(e.imageFile.size > 2500000){
              this.alertService.error('Não foi possível realizar o upload. Tamanho do arquivo é muito grande.');
              return;
            }
          }
      
          if (!this.data.isDuplicar) {
            this.projectsService.create(obj, 'salvar-simples').subscribe((res: Project) => {
              if (e.imageFile !== null) {
                let imgFormData = new FormData();
                imgFormData.append('file', e.imageFile, e.imageFile.name);
      
                this.projectsService.uploadImg(imgFormData, 'upload-capa', res.id).subscribe((res) => {
                  this.alertService.success('Projeto atualizado com sucesso');
                  if (this.isResponsibleAMember(obj, this.data.memberList)) {
                    this.dismiss(true, obj.responsavel.id);
                  } else {
                    this.dismiss(true);
                  }
                  this.data.project = res;
                }, (err) => {})
              } else{
                this.alertService.success('Projeto atualizado com sucesso');
                if (this.isResponsibleAMember(obj, this.data.memberList)) {
                  this.dismiss(true, obj.responsavel.id);
                } else {
                  this.dismiss(true);
                }
                this.data.project = res;
              }
            }) 
          } else {
            this.projectsService.create(obj, 'duplicar').subscribe((res: Project) => {
              if (e.imageFile !== null) {
                let imgFormData = new FormData();
                imgFormData.append('file', e.imageFile, e.imageFile.name);
      
                this.projectsService.uploadImg(imgFormData, 'upload-capa', res.id).subscribe((res) => { 
                  this.dismiss(true);
                  this.alertService.success('Projeto duplicado com sucesso');
                })
              } else {
                this.dismiss(true);
                this.alertService.success('Projeto duplicado com sucesso');
              } 
            }) 
          }
    })

    
  }

  isResponsibleAMember(obj: Project, memberList: Member[]): boolean {
    if (memberList) {
      if (memberList.length > 0 && memberList.some(el => el.usuario.id === obj.responsavel.id)) {
        return true;
      }
    }
    return false;
  }

  dismiss(shouldUpdateList: boolean = false, memberIdToBeRemoved?: number): void {
    this.dialogRef.close({ shouldUpdateList: shouldUpdateList, memberIdToBeRemoved: memberIdToBeRemoved });
  }
}
