import { PlanoAcao } from './../../shared/models/plano-acao.model';
import { UtilService } from './../util.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../base/base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ActionPlanService extends BaseService<any>{
  serviceName: string = "plano-acao";

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  listAllByProjeto(idProjeto: number){
    return this.http.post<PlanoAcao[]>(this.urlApi + "public/" + this.serviceName + '/' + idProjeto, {});
  }
}
