import {
    animation, trigger, animateChild, group,
    transition, animate, style, query
} from '@angular/animations';

export const slideInAnimation =
    trigger('routeAnimations', [
        transition(':enter', [
            style({ opacity: 0, transform: 'translateY(10px)' }),
            animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
        ]),
        transition(':leave', [
            style({ opacity: 1, transform: 'translateY(0px)' }),
            animate('500ms', style({ opacity: 0, transform: 'translateY(10)' })),
        ])
        // transition('* <=> FilterPage', [
        //     style({ position: 'relative' }),
        //     query(':enter, :leave', [
        //         style({
        //             position: 'absolute',
        //             top: 0,
        //             left: 0,
        //             width: '100%'
        //         })
        //     ]),
        //     query(':enter', [
        //         style({ left: '-100%' })
        //     ]),
        //     query(':leave', animateChild()),
        //     group([
        //         query(':leave', [
        //             animate('200ms ease-out', style({ left: '100%' }))
        //         ]),
        //         query(':enter', [
        //             animate('300ms ease-out', style({ left: '0%' }))
        //         ])
        //     ]),
        //     query(':enter', animateChild()),
        // ])
    ]);