import { Permission } from './permission.model';


export class UserAccess {
    permissao: Permission;
    uf: string[] | any;

    constructor() {
        this.permissao = new Permission();
        this.uf = [];
    }
}