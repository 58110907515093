import { Injectable } from '@angular/core';
import { Estado } from '../shared/models/estado.model';
import { EPermissionType } from './../enums/EPermissionType';
import { Member } from './../shared/models/member.model';
import { Project } from './../shared/models/project.model';
import { UserAccess } from './../shared/models/user-access.model';
import { User } from './../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  paramsToQueryString(params: any, isFormData: boolean = false): string {
    let result = isFormData ? '' : '?';

    for (let propertyName in params) {
      if (params && params[propertyName] !== undefined && params[propertyName] !== null && params[propertyName] !== '') {
        if (Array.isArray(params[propertyName])) {
          for (const item of params[propertyName]) {
            result += propertyName + '=' + item + '&';
          }
        } else {
          result += propertyName + '=' + params[propertyName] + '&';
        }
      }
    }
    return result.substring(0, result.length - 1);
  }

  queryStringToParams(search: string): {} {
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  }

  isAdmin(userAccesses: UserAccess[]): boolean {
    for (let i = 0; i < userAccesses.length; i++) {
      if (userAccesses[i].permissao.tipo === EPermissionType.ADMINISTRADOR) {
        return true;
      }
    }
    return false;
  }

  isCoordenador(userAccesses: UserAccess[]): boolean {
    for (let i = 0; i < userAccesses.length; i++) {
      if (userAccesses[i].permissao.tipo === EPermissionType.COORDENADOR) {
        return true;
      }
    }

    return false;
  }

  havePermissionFromState(userAccesses: UserAccess[], state: Estado[], permissionType: EPermissionType): boolean {
    for (let i = 0; i < userAccesses.length; i++) {
      if (userAccesses[i].permissao.tipo === permissionType) {

        for (let k = 0; k < state.length; k++) {
          if (userAccesses[i].uf === state[k].uf) {
            return true;
          }
        }

      }
    }
    return false;
  }

  isResponsible(project: Project, user: User): boolean {
    if (project.responsavel.email === user.email) {
      return true;
    }
    return false;
  }

  isProjectMember(user: User, memberList: Member[]): boolean {
    for (let i = 0; i < memberList.length; i++) {
      if (memberList[i].usuario.email === user.email) {
        return true;
      }
    }
    return false;
  }

  useDefaultProfilePic(): string {
    return './assets/img/default-user-img.svg';
  }

  validarCNPJ(data): any {

    if (data.value == "" || data.value == null) return null;

    let cnpj = data.value.toString();

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return null;

    if (cnpj.length != 14)
      return { invalidCNPJ: true };

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return { invalidCNPJ: true };

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return { invalidCNPJ: true };

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return { invalidCNPJ: true };

    return null;

  }

  getFileName(str: string): string {
    return /[^/]*$/.exec(str)[0];
  }

  getFileExtension(str: string): string {
    return str.substr(str.lastIndexOf(".") + 1);
  }

  downloadBlob(blob, name) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }

  truncateDecimals(number: number, digits: number) {
    var multiplier = Math.pow(10, digits),
      adjustedNum = number * multiplier,
      truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

    return truncatedNum / multiplier;
  };

  sortByProp(array: any[], prop: string){
    return array.sort(function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      }
      if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    });
  }
}
