// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  amei_login: "https://amei.homolog.kubernetes.sebrae.com.br/auth/realms/dev/.well-known/openid-configuration",
  redirect_uri: window.location.origin,
  api_url: window.location.origin.includes('localhost') ? 'https://dev.sebrae-eli.com.br/api/' : window.location.origin + '/api/',
  clarity_id: "zzzzzzzz",
  
  //api_url: window.location.origin.includes('localhost') ? 'http://localhost:8080/api/' : window.location.origin + '/api/',

  // api_url: 'http://dev-ecossistemadeinovacao.apps.ap0.pr.sebrae.com.br/api/',
  // redirect_uri: 'http://localhost:4200/',
  // amei_login: "https://amei.homolog.kubernetes.sebrae.com.br/auth/realms/dev/.well-known/openid-configuration",
  
};
