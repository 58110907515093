import { RegioesEstadosQtdProjetosBR } from './../../shared/models/regioes-estados-qtd-projetos-br.model';
import { Paginate } from './../../shared/models/paginate.model';
import { Project } from './../../shared/models/project.model';
import { Observable } from 'rxjs';
import { BaseService } from './../base/base.service';
import { UtilService } from './../util.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends BaseService<any>{
  serviceName: string = 'projetos';

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  uploadImg(payload: {}, modelName?: string, id?: number | {}): Observable<any> {
    return this.http.post(this.urlApi + this.serviceName + '/' + modelName + '/' + id, payload, { headers: new HttpHeaders({ 'no-content-type': 'true', 'accept': '*/*' }) });
  }

  searchProjectId(id: number) {
    return this.http.post(this.urlApi + this.serviceName + '/' + id, '');
  }

  listarPublicPaginado(filtro: any, page: number, size: number) {
    return this.http.post< Paginate<Project>>(this.urlApi + "public/" + this.serviceName + "?page=" + page + "&size=" + size, filtro);
  }

  pesquisarPorIdPublic(id: number) {
    return this.http.post<Project>(this.urlApi + "public/" + this.serviceName + '/' + id, '');
  }

  listAllByRegiaoBR() {
    return this.http.get<RegioesEstadosQtdProjetosBR>(this.urlApi + "public/" + this.serviceName + '/list-all-by-regiao');
  }

  listMaturidades(){
    return this.http.get<number[]>(this.urlApi + this.serviceName + '/lista-maturidades');
  }

  listMaturidadesPublic(){
    return this.http.get<number[]>(this.urlApi + "public/" + this.serviceName + '/lista-maturidades');
  }

  filtrarNaoPaginado(filtro: any) {
    return this.http.post<Project[]>(this.urlApi + this.serviceName + "/list", filtro);
  }
}
