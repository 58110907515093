import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-btn-ir-topo',
  templateUrl: './btn-ir-topo.component.html',
  styleUrls: ['./btn-ir-topo.component.scss']
})
export class BtnIrTopoComponent implements OnInit {
  podeExibir: boolean = false;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {    
    this.podeExibir = (document.documentElement.scrollTop !== 0);
  }

  constructor() { }

  ngOnInit() {
  }

  rolarParaTopo(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
