import { RegiaoQtdProjetos } from './../../../../../shared/models/regiao-qtd-projetos.model';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-regiao-sudeste',
  templateUrl: './regiao-sudeste.component.html',
  styleUrls: ['./regiao-sudeste.component.scss']
})
export class RegiaoSudesteComponent implements OnInit {

  @Input() qtdProjetosRegSudeste: RegiaoQtdProjetos;
  @Input() isLogado: boolean = false;

  qtdProjetosMG: string;
  qtdProjetosES: string;
  qtdProjetosRJ: string;
  qtdProjetosSP: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.inicializarQtdProjEstado();
  }

  findQtdProjetosByEstado(uf: string){
    try{
      let qtd = this.qtdProjetosRegSudeste.projetosEstado.find(estado => estado.uf.toUpperCase() === uf.toUpperCase()).qtdProjetos;

      if(qtd > 0 && qtd < 10){
        return "0" + qtd;
      }else if(qtd >= 10){
        return qtd.toString();
      }
    }catch(error){}
    return "&nbsp;0";
  }

  inicializarQtdProjEstado(){
    this.qtdProjetosMG = this.findQtdProjetosByEstado("MG");
    this.qtdProjetosES = this.findQtdProjetosByEstado("ES");
    this.qtdProjetosRJ = this.findQtdProjetosByEstado("RJ");
    this.qtdProjetosSP = this.findQtdProjetosByEstado("SP");
  }

  irParaCatalogo(uf: string, nome: string){
    let catalogo = this.isLogado? "/projects" : "/catalogo-projetos"
    this.router.navigate([catalogo], {
      state: {estadoSelecionadoCaller: {uf: uf, nome: nome}}
    });
  }
}
