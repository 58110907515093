import { Segment } from './segments.model';
import { NotaVertente } from 'src/app/shared/models/nota-vertente.model';
import { AnexoPlano } from './anexo-plano.model';
import { Estado } from './estado.model';
import { Municipio } from './municipio.model';
import { Potential } from './potential.model';
import { Regional } from './regional.model';
import { Territorio } from './territorio.model';
import { User } from './user.model';
import { Vocation } from './vocacao.model';
import { Sector } from './sector.model';

export class Project {
    id: number;
    estados: Estado[];
    municipios: Municipio[];
    territorios: Territorio[];
    regionais: Regional[];
    descricao: string;
    descricaoAntiga: string;
    responsavel: User;
    pathCapa: string;
    potenciais: Potential[];
    vocacoes: Vocation[];
    versoes: Project[];

    imageFile: File | null;
    dataAnoref: number;

    //Melhorias Ecossistema UC021
    resumoHtml: string;
    
    //Melhorias Ecossistema UC019
    anexosPlanoAcao: AnexoPlano[];
    anexosPlanoSetorial: AnexoPlano[];

    labelVersoes: string = null;

    notaVertente: NotaVertente;
    setores: Sector[] = [];

    constructor(model?: Project) {
        this.id = model ? model.id : 0;
        this.estados = model ? model.estados : [];
        this.municipios = model ? model.municipios : [];
        this.territorios = model ? model.territorios : [];
        this.regionais = model ? model.regionais : [];
        this.descricao = model ? model.descricao : '';
        this.descricaoAntiga = model ? model.descricaoAntiga : '';
        this.responsavel = model ? model.responsavel : new User();
        this.pathCapa = model ? model.pathCapa : '';
        this.potenciais = model ? model.potenciais : [];
        this.vocacoes = model ? model.vocacoes : [];

        this.imageFile = null;
        this.dataAnoref = model ? model.dataAnoref : 0;

        this.resumoHtml = model ? model.resumoHtml : '';
        this.versoes = model ? model.versoes : [];
        this.anexosPlanoAcao = model ? model.anexosPlanoAcao : [];
        this.anexosPlanoSetorial = model ? model.anexosPlanoSetorial : [];
        this.labelVersoes = model ? model.labelVersoes : "";
        this.notaVertente = model ? model.notaVertente : null;
        this.setores = model ? model.setores : [];
    }
}