import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out', 
                    style({ height: 30, opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ height: 30, opacity: 1 }),
            animate('1s ease-in', 
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() diameter:number = 50;
  @Input() availableHeight: boolean = false;

  height: number;
  constructor() { 
    // let headerHeight = document.getElementById('header').clientHeight;
    // let footerHeight = document.getElementById('footer').clientHeight;
    // this.height = window.innerHeight - headerHeight - footerHeight;
  }

  ngOnInit() {
  }


}
