import { RegiaoQtdProjetos } from './../../../../../shared/models/regiao-qtd-projetos.model';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-regiao-centro-oeste',
  templateUrl: './regiao-centro-oeste.component.html',
  styleUrls: ['./regiao-centro-oeste.component.scss']
})
export class RegiaoCentroOesteComponent implements OnInit {

  @Input() qtdProjetosRegCentroOeste: RegiaoQtdProjetos;
  @Input() isLogado: boolean = false;

  qtdProjetosMT: string;
  qtdProjetosMS: string;
  qtdProjetosGO: string;
  qtdProjetosDF: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.inicializarQtdProjEstado();
  }

  findQtdProjetosByEstado(uf: string){
    try{
      let qtd = this.qtdProjetosRegCentroOeste.projetosEstado.find(estado => estado.uf.toUpperCase() === uf.toUpperCase()).qtdProjetos;

      if(qtd > 0 && qtd < 10){
        return "0" + qtd;
      }else if(qtd >= 10){
        return qtd.toString();
      }
    }catch(error){
    }
    return "&nbsp;0";
  }

  inicializarQtdProjEstado(){
    this.qtdProjetosMT = this.findQtdProjetosByEstado("MT");
    this.qtdProjetosMS = this.findQtdProjetosByEstado("MS");
    this.qtdProjetosGO = this.findQtdProjetosByEstado("GO");
    this.qtdProjetosDF = this.findQtdProjetosByEstado("DF");

  }

  irParaCatalogo(uf: string, nome: string){
    let catalogo = this.isLogado? "/projects" : "/catalogo-projetos"
    this.router.navigate([catalogo], {
      state: {estadoSelecionadoCaller: {uf: uf, nome: nome}}
    });
  }
}
