import { AnalysisPotentialVocationService } from './../../../../services/analysis-potential-vocation/analysis-potential-vocation.service';
import { Analysis, LocalVocacacoes } from './../../../../shared/models/analysis.model';
import { Member } from './../../../../shared/models/member.model';
import { Project } from './../../../../shared/models/project.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-details-analise-public',
  templateUrl: './project-details-analise-public.component.html',
  styleUrls: ['./project-details-analise-public.component.scss']
})
export class ProjectDetailsAnalisePublicComponent implements OnInit {

  @Input() project: Project = new Project();
  @Input() memberList: Member[] = [];

  id: number = 0;
  analysis: Analysis = new Analysis();
  isLoading: boolean = true;

  graphDataSubscription: Subscription = new Subscription();
  userDataSubscription: Subscription = new Subscription();

  vocations: LocalVocacacoes[] = [];
  constructor(
    private analysisPotentialVocationService: AnalysisPotentialVocationService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.id = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    this.getGraphData();
  }



  getGraphData(): void {
    this.graphDataSubscription = this.analysisPotentialVocationService.getPublicById(this.id).subscribe((res: Analysis) => {
      this.analysis = res;
      this.vocations = res.vocacoes;
      // this.progressiveLoad();
      this.isLoading = false;
    })
  }

  ngOnDestroy(): void {
    this.userDataSubscription.unsubscribe();
    this.graphDataSubscription.unsubscribe();
  }

}
