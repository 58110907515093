import { Vertente } from 'src/app/shared/models/vertente.model';
import { UtilService } from './../util.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../base/base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VertenteService extends BaseService<any>{
  serviceName: string = "vertente";
  static vertentesNotaUnica = ["POLÍTICAS PÚBLICAS", "CAPITAL", "GOVERNANÇA"];

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  listAll(){
    return this.http.get<Vertente[]>(this.urlApi + this.serviceName + "/buscarTodasVertente");
  }

  listAllPublic(){
    return this.http.get<Vertente[]>(this.urlApi + "public/" + this.serviceName);
  }
}
