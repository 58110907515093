import { Segment } from './../../shared/models/segments.model';
import { UtilService } from './../util.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../base/base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SegmentsService extends BaseService<any> {
  serviceName: string = "segmentos";

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  listAllPublic(){
    return this.http.get<Segment[]>(this.urlApi + "public/" + this.serviceName);
  }
}
