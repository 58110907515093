import { RegiaoQtdProjetos } from './../../../../../shared/models/regiao-qtd-projetos.model';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-regiao-norte',
  templateUrl: './regiao-norte.component.html',
  styleUrls: ['./regiao-norte.component.scss']
})
export class RegiaoNorteComponent implements OnInit {

  @Input() qtdProjetosRegNorte: RegiaoQtdProjetos;
  @Input() isLogado: boolean = false;

  qtdProjetosAC: string;
  qtdProjetosAM: string;
  qtdProjetosRO: string;
  qtdProjetosRR: string;
  qtdProjetosPA: string;
  qtdProjetosAP: string;
  qtdProjetosTO: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.inicializarQtdProjEstado();
  }

  findQtdProjetosByEstado(uf: string){
    try{
      let qtd = this.qtdProjetosRegNorte.projetosEstado.find(estado => estado.uf.toUpperCase() === uf.toUpperCase()).qtdProjetos;

      if(qtd > 0 && qtd < 10){
        return "0" + qtd;
      }else if(qtd >= 10){
        return qtd.toString();
      }
    }catch(error){}
    return "&nbsp;0";
  }

  inicializarQtdProjEstado(){
    this.qtdProjetosAC = this.findQtdProjetosByEstado("AC");
    this.qtdProjetosAM = this.findQtdProjetosByEstado("AM");
    this.qtdProjetosRO = this.findQtdProjetosByEstado("RO");
    this.qtdProjetosRR = this.findQtdProjetosByEstado("RR");
    this.qtdProjetosPA = this.findQtdProjetosByEstado("PA");
    this.qtdProjetosAP = this.findQtdProjetosByEstado("AP");
    this.qtdProjetosTO = this.findQtdProjetosByEstado("TO");
  }

  irParaCatalogo(uf: string, nome: string){
    let catalogo = this.isLogado? "/projects" : "/catalogo-projetos"
    this.router.navigate([catalogo], {
      state: {estadoSelecionadoCaller: {uf: uf, nome: nome}}
    });
  }

}
