import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { EPermissionType } from './../../../enums/EPermissionType';
import { AuthBaseService } from './../../../services/auth/auth-base.service';
import { UserAccessService } from './../../../services/user-access/user-access.service';
import { UserService } from './../../../services/user/user.service';
import { UtilService } from './../../../services/util.service';
import { Paginate } from './../../models/paginate.model';
import { UserAccess } from './../../models/user-access.model';
import { User } from './../../models/user.model';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  @Input() isAddingMemberInProjectMode: boolean = false;
  @Input() triggerChangesInChildComponent: boolean = false;

  @Input() selectedMemberList: User[] = [];
  @Output() isSelectedEmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateMemberListEmitter: EventEmitter<User[]> = new EventEmitter();

  shouldUpdateUserList: Subject<null> = new Subject();

  currentUserData: { user: User, userAccess: UserAccess[] } = { user: new User(), userAccess: [] };
  form: FormGroup = new FormGroup({});
  filter: FilterForm = new FilterForm();
  dataSubscription: Subscription = new Subscription();
  formSubscription: Subscription = new Subscription();
  shouldUpdateUserListSubscription: Subscription = new Subscription();

  users: User[] = [];
  userAccesses: UserAccess[] = [];
  isLoading: boolean = false;

  statesList: string[] = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO',
  ];

  paginateConfig: {
    totalItems: number,
    itemsPerPage: number,
    currentPage: number
  } = {
      totalItems: 0,
      itemsPerPage: new FilterForm().size,
      currentPage: 1,
    }

  get EPermissionType() {
    return EPermissionType;
  }

  constructor(
    private utilservice: UtilService,
    private authBaseService: AuthBaseService,
    private userService: UserService,
    private userAccessService: UserAccessService,
    private activatedRoute: ActivatedRoute
  ) {
    this.newForm(new FilterForm());
    this.watchForm();
    this.getCurrentUserData();

    this.watchIfShouldUpdateList();
  }

  ngOnInit(): void {
    this.getUserAccesses();
  }

  getUserAccesses(): void {
    this.userAccessService.getAll(null, 'buscar-permissoes-que-eu-posso-atribuir').subscribe((res: UserAccess[]) => {
      this.userAccesses = res;

      this.mountStateList();
    })
  }

  mountStateList(): void {
    if (!this.utilservice.isAdmin(this.currentUserData.userAccess)) {
      this.statesList = [];

      for (let i = 0; i < this.userAccesses.length; i++) {
        for (let j = 0; j < this.userAccesses[i].uf.length; j++) {

          if (!this.statesList.includes(this.userAccesses[i].uf[j])) {
            this.statesList.push(this.userAccesses[i].uf[j]);
          }

        }
      }

    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (typeof simpleChanges.triggerChangesInChildComponent?.currentValue === 'boolean' && !this.isAddingMemberInProjectMode) {
      this.getUserList(new FilterForm());
    }

    if (this.isAddingMemberInProjectMode) {
      for (let i = 0; i < this.users.length; i++) {
        this.users[i].isSelected = this.selectedMemberList.includes(this.users[i]) ? true : false;
      }
    }
  }

  watchIfShouldUpdateList(): void {
    this.shouldUpdateUserList.subscribe((res: null) => {
      this.getUserList(new FilterForm());
    })
  }

  newForm(model: FilterForm): void {
    this.form = new FormGroup({
      campoAberto: new FormControl(model.campoAberto),
      tipoPermissao: new FormControl(model.tipoPermissao),
      uf: new FormControl(model.uf),
      page: new FormControl(model.page),
      size: new FormControl(model.size),
    })
  }

  getCurrentUserData(): void {
    this.authBaseService.getLoggedUser().subscribe((res: { user: User, userAccess: UserAccess[] }) => {

      if (res.user.id > 0) {
        this.currentUserData = res;
        this.form.updateValueAndValidity();
      }
    })
  }

  setPagination(res: Paginate<User>): void {
    this.paginateConfig = {
      totalItems: res.totalResults,
      itemsPerPage: this.paginateConfig.itemsPerPage,
      currentPage: res.page
    }
  }

  watchForm(): void {
    this.formSubscription = this.form.valueChanges.subscribe((res: FilterForm) => {

      if (this.filter.campoAberto !== res.campoAberto) {

        this.filter.page = 1;
        this.filter.campoAberto = res.campoAberto;
      } else {

        this.filter = new FilterForm(
          {
          campoAberto: res.campoAberto,
            page: res.page,
            size: res.size,
            tipoPermissao: res.tipoPermissao,
            uf: res.uf,

            idProjeto: this.activatedRoute.snapshot.params.id
          }
        );
      }

      this.getUserList(this.filter);
    })
  };

  resetarForm(){
   this.form.get("tipoPermissao")?.reset();
   this.form.get("campoAberto")?.reset();
   this.form.get("uf")?.reset();
  }

  getUserList(filter: FilterForm, shouldUpdateList: boolean = true): void {

    if (shouldUpdateList) {
      this.dataSubscription.unsubscribe();
      this.isLoading = true;
      this.users = [];
      this.dataSubscription = this.userService.getAll(filter).subscribe((res: Paginate<User>) => {
        this.setPagination(res);
        this.users = res.list;

        this.users.forEach(user => user.isSelected = false);

        this.selectedMemberList = [];
        this.updateMemberListEmitter.emit(this.selectedMemberList);
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      })
    }
  }

  mountArrayOfStates(): string[] {
    let stateArray: string[] = [];

    for (let i = 0; i < this.currentUserData.userAccess.length; i++) {

      if (!stateArray.includes(this.currentUserData.userAccess[i].uf as string)) {
        stateArray.push(this.currentUserData.userAccess[i].uf as string);
      }

    }

    return stateArray;
  }

  setPage(page: number): void {
    this.form.get('page').setValue(page);
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
    this.shouldUpdateUserList.unsubscribe()
  }
}

export class FilterForm {
  campoAberto: string;
  tipoPermissao: EPermissionType | null;
  uf: string[] | string;
  page: number;
  size: number;

  idProjeto: number | null;

  constructor(model?: FilterForm) {
    this.campoAberto = model ? model.campoAberto : '';
    this.tipoPermissao = model ? model.tipoPermissao : null;
    this.uf = model ? model.uf : [];
    this.page = model ? model.page : 1;
    this.size = model ? model.size : 20;

    this.idProjeto = model ? model.idProjeto : null;
  }
}