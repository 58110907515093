import { Project } from 'src/app/shared/models/project.model';
import { Vocation } from 'src/app/shared/models/vocacao.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vocacao-table-public',
  templateUrl: './vocacao-table-public.component.html',
  styleUrls: ['./vocacao-table-public.component.scss']
})
export class VocacaoTablePublicComponent implements OnInit {

  @Input() submitProjectObj: Project = new Project(null);
  @Input() updateOnCheck: boolean = false;
  @Input() canChange: boolean = false;
  @Input() loadListTrigger: boolean = false;

  selectedVocation: Vocation[] = [];

  shouldLoad: boolean = true;

  list: Vocation[] = [];
  constructor(
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.submitProjectObj.vocacoes.length > 0 && this.shouldLoad) {
      this.list = this.submitProjectObj.vocacoes;

      this.shouldLoad = false;
    }
  }

  trackByFn(index, item) {
    return index;
  }

}
