import { Conteudo } from './../../../../shared/models/conteudo.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ConteudoService } from '../home-page-area-publica/conteudo-home.service';


@Component({
  selector: 'app-saiba-mais-home',
  templateUrl: './saiba-mais-home.component.html',
  styleUrls: ['./saiba-mais-home.component.scss']
})
export class SaibaMaisHomeComponent implements OnInit {

  @ViewChild('carousel') carousel;

  conteudo: Conteudo;
  conteudos: Conteudo[] = [];
  outrosItens: Conteudo[] = [];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    nav: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
  }

  conteudoHTML: SafeHtml;

  constructor(
    private router: Router,
    private conteudoService: ConteudoService,
    private _sanitizer: DomSanitizer
  ) {
    try{
      this.conteudo = this.router.getCurrentNavigation().extras.state.conteudo;
      this.conteudo.dscPublicado = this.formatHtmlString(this.conteudo.dscPublicado);
    }catch(error){
      this.retornarHome();
    }
   }

  ngOnInit(): void {
    window.scroll(0,0);
    this.carregarDemaisConteudos();
    this.conteudoHTML = this._sanitizer.bypassSecurityTrustHtml(this.conteudo.dscPublicado);
  }

  isVertente(){
    return this.conteudo.tipo.toUpperCase() === ConteudoService.TIPO_VERTENTE.toUpperCase();
  }

  isTiming(){
    return this.conteudo.tipo.toUpperCase() === ConteudoService.TIPO_TIMING.toUpperCase();
  }

  isPassoImplantacao(){
    return this.conteudo.tipo.toUpperCase() === ConteudoService.TIPO_IMPLANTACAO.toUpperCase();
  }


  carregarDemaisConteudos(){
    this.conteudoService.filtrar({tipo: this.conteudo.tipo}).subscribe(
      (conteudos: Conteudo[]) => {
        this.outrosItens = conteudos.filter(cont => cont.id !== this.conteudo.id);
      }, (error) => {}
    );
  }

  formatHtmlString(htmlStr: string){
    return htmlStr.replace('\"', '"');
  }

  retornarHome(){
    let containerBack = "vertentes-container";

    if(this.conteudo?.tipo == ConteudoService.TIPO_TIMING){
      containerBack = "containter-timings";
    } else if(this.conteudo?.tipo == ConteudoService.TIPO_IMPLANTACAO){
      containerBack = "container-ciclo";
    }

    this.router.navigate(["/"], {
      state: {scroll: {
        tipo: this.conteudo?.tipo,
        id: containerBack
      }}
    });
  }
}
