import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { EPermissionType } from "src/app/enums/EPermissionType";
import { UtilService } from "src/app/services/util.service";
import { UserAccess } from "src/app/shared/models/user-access.model";
import { environment } from "src/environments/environment";
import { AlertService } from "./../../../../../services/alert.service";
import { publicRoutes } from './../../../../../views/area-publica/area-publica.routing';
import { Project } from "./../../../../models/project.model";

@Component({
  selector: "app-project-versions-modal",
  templateUrl: "./project-versions-modal.component.html",
  styleUrls: ["./project-versions-modal.component.scss"],
})
export class ProjectVersionsModalComponent implements OnInit {
  component: { project: Project, caller: string };
  isPublicCaller: boolean = false;
  api_url = environment.api_url;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { project: Project, caller: string, userAccess: UserAccess[] },
    private dialogRef: MatDialogRef<ProjectVersionsModalComponent>,
    private utilService: UtilService,
    private alertService: AlertService,
    private router: Router,
    private cdRef:ChangeDetectorRef
    ) {
      this.isPublicCaller = this.isPublicRoute(data.caller);
    }
    
  imageUrl: string = "";
  projetoSelecionado: Project;
  timestamp: number = new Date().getTime();

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.timestamp = new Date().getTime();
    this.cdRef.detectChanges();
  }

  verificaCardSelecionado(project: Project) {
    if (this.projetoSelecionado) {
      if (this.projetoSelecionado.id === project.id) {
        return true;
      } else {
        return false;
      }
    }
  }

  deletar() {
    this.dialogRef.close({ abrirModalExcluir: true, projetoParaExcluir: this.projetoSelecionado });
  }

  acessar() {
   let urlCaller = this.data.caller;
    if (this.projetoSelecionado === null || !this.projetoSelecionado) {
      this.alertService.warning(
        "Selecione o projeto desejado para acessar"
      );
    } else {
      if(publicRoutes.includes(urlCaller)){
        this.router.navigate([('./catalogo-projetos/details/' + this.projetoSelecionado.id + '/0')]);
      }else{
        this.router.navigate([
          "./projects/details/" + this.projetoSelecionado.id + "/0",
        ]);        
      }
      this.dismiss(true);
    }
  }

  onSelectCard(project: Project) {
    this.projetoSelecionado = project;
  }

  dismiss(shouldUpdateList: boolean = false): void {
    this.dialogRef.close({ shouldUpdateList: shouldUpdateList });
  }

  getImagemProjeto(project: Project) {
    return environment.api_url + "public/projetos/" + project.id + "/capa?t=" + this.timestamp;
  }

  isPublicRoute(url: string){
    let isPublic = false;
    for(let i = 0; i < publicRoutes.length; i++){
      let route = publicRoutes[i];
      isPublic = route.includes(url) ||  (url?.startsWith(route) && route !== "/");

      if(isPublic){
        break;
      }
    }
    return isPublic;
  }

  canDelete(): boolean {

    if(!this.data.userAccess) {
      return false;
    }

    if (this.utilService.isAdmin(this.data.userAccess)) {
      return true;
    }

    if (this.utilService.havePermissionFromState(this.data.userAccess, this.data.project.estados, EPermissionType.COORDENADOR)) {
      return true;
    }

    return false;
  }

}
