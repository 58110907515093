import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { environment } from './../../../../../../../environments/environment';
import { AuthBaseService } from './../../../../../../services/auth/auth-base.service';
import { UserService } from './../../../../../../services/user/user.service';
import { Paginate } from './../../../../../models/paginate.model';
import { Project } from './../../../../../models/project.model';
import { UserAccess } from './../../../../../models/user-access.model';
import { User } from './../../../../../models/user.model';

import { ProjectUpdateModalComponent } from './../../project-update-modal/project-update-modal.component';

@Component({
  selector: 'app-project-create-configuracao',
  templateUrl: './project-create-configuracao.component.html',
  styleUrls: ['./project-create-configuracao.component.scss']
})
export class ProjectCreateConfiguracaoComponent implements OnInit {
  @Input() fullWidth: boolean = false;
  @Input() project: Project = new Project();
  @Input() isDuplicar: boolean = false;

  quillConfiguration = QuillConfiguration;

  storageUrl = environment.api_url + 'public/';

  public filteredTags: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  public tagCtrl: FormControl = new FormControl();
  public tagFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  tags: User[] = [];
  @Input() submitProjectObj: Project = new Project();
  @Output() nextEmitter: EventEmitter<'prev' | 'next'> = new EventEmitter();
  @Output() configurationEmitter: EventEmitter<Project> = new EventEmitter();

  imagePreview: { fileType: string, base64: string } = { fileType: '', base64: '' };

  form: FormGroup = new FormGroup({});

  dataSubscription: Subscription = new Subscription();

  isLoading: boolean = false;

  timestamp: number = new Date().getTime();

  constructor(
    private userService: UserService,
    private authBaseService: AuthBaseService,
    private updateModalDialogRef: MatDialogRef<ProjectUpdateModalComponent>,
    private alertService: AlertService
  ) {
    this.newForm(new FormData());
  }

  editorStyle = {
    'height': '210px',
    'backgroundColor': '#ffffff',
    'overflow-y': 'auto'
  };

  newForm(model?: FormData): void {
    this.form = new FormGroup({
      user: new FormControl(model.user),
      descricao: new FormControl(model.descricao, Validators.required),
      dataAnoRef: new FormControl(model.dataAnoRef, Validators.required),
      resumoHtml: new FormControl(model.resumoHtml)
    });
  }

  ngOnInit(): void {
    this.tagSelector();
    this.watchForm();

    this.fillFields();

  }

  fillFields(): void {
    if (this.project?.id === 0) {
      this.authBaseService.getLoggedUser().subscribe((res: { user: User, userAccess: UserAccess[] }) => {
        let user: User = new User();
        user = res.user;
        user.permissoes = res.userAccess;

        this.submitProjectObj.responsavel = res.user;
        this.submitProjectObj.dataAnoref = this.project.dataAnoref;
        this.submitProjectObj.resumoHtml = this.project.resumoHtml;
      })
    } else {
      this.submitProjectObj.responsavel = this.project.responsavel;
      this.submitProjectObj.dataAnoref = this.project.dataAnoref;
      this.submitProjectObj.resumoHtml = this.project.resumoHtml;

      this.form.get('descricao').setValue(this.project.descricao);
      this.form.get('dataAnoRef').setValue(this.project.dataAnoref);
      this.form.get('resumoHtml').setValue(this.project.resumoHtml);

    }
  }

  uploadFile(event: File[]): void {
    if (event.length > 0) {

      if(event[0].size > 2500000){
        this.alertService.error('Não foi possível realizar o upload. Tamanho do arquivo é muito grande.');
        return;
      }

      let reader = new FileReader();

      reader.readAsDataURL(event[0]);
      reader.onload = () => {
        this.imagePreview.base64 = this.clearImgHeader(reader?.result);
      }
      this.submitProjectObj.imageFile = event[0];
    }
  }

  clearImgHeader(imgStringBase64: string | ArrayBuffer) {
    let base64 = '';
    if (imgStringBase64.toString() && imgStringBase64 != "" && imgStringBase64.toString().includes("/") && imgStringBase64.toString().includes(";") && imgStringBase64.toString().includes(",")) {
      this.imagePreview.fileType = imgStringBase64.toString().split('/')[1].split(';')[0];
      base64 = imgStringBase64.toString().split(',')[1];
    }
    return base64;
  }

  watchForm(): void {
    this.form.valueChanges.subscribe((res: { user: User, descricao: string, dataAnoRef: number, resumoHtml: string }) => {

      if (res.user) {
        this.submitProjectObj.responsavel = res.user;
      }

      this.submitProjectObj.descricao = res.descricao;
      this.submitProjectObj.dataAnoref = res.dataAnoRef;
      this.submitProjectObj.resumoHtml = res.resumoHtml;

    })
  }

  getUser(campoAberto: string): void {
    this.userService.getAll({ campoAberto: campoAberto, page: 1, size: 200 }).subscribe((res: Paginate<User>) => {
      this.filteredTags.next(
        res.list
      );
    })
  }

  goTo(type: 'prev' | 'next'): void {
    this.isLoading = true;
    if (type === 'prev') {
      this.nextEmitter.emit(type);
    } else if (this.form.valid && type === "next") {
      this.nextEmitter.emit(type);
      this.configurationEmitter.emit(this.submitProjectObj)
    } else {
      this.form.markAllAsTouched();
    }
  }

  dismiss(): void {
    this.updateModalDialogRef.close();
  }

  // ====================== tag selector ====================== //
  tagSelector() {
    this.filteredTags.next(this.tags.slice());

    this.tagFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTags();
      });
  }

  filterTags() {
    if (!this.tags) {
      return;
    }
    let search = this.tagFilterCtrl.value;

    this.getUser(search);
  }
}

export const QuillConfiguration = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'align': [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    ['link'],
    ['image'],
    ['video'],
    ['clean'],
  ],
}
class FormData {
  user: string | null;
  descricao: string | null;
  dataAnoRef: number | null;
  resumoHtml: string | null;

  constructor() {
    this.user = null;
    this.descricao = null;
    this.dataAnoRef = null;
    this.resumoHtml = null;
  }

}
