import { AlertService } from './../../../services/alert.service';
import { CalculatedPotencialService } from './../../../services/calculated-potential/calculated-potencial.service';
import { UtilService } from './../../../services/util.service';

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalysisPotentialVocationService } from 'src/app/services/analysis-potential-vocation/analysis-potential-vocation.service';
import { Potential } from '../../models/potential.model';
import { Project } from '../../models/project.model';

@Component({
  selector: 'app-potencial-table',
  templateUrl: './potencial-table.component.html',
  styleUrls: ['./potencial-table.component.scss']
})
export class PotencialTableComponent implements OnInit {
  @Input() submitProjectObj: Project = new Project(null);
  @Input() updateOnCheck: boolean = false;
  @Input() canChange: boolean = false;
  @Input() loadListTrigger: boolean = false;

  selectedPotential: Potential[] = [];

  shouldLoad: boolean = true;

  list: Potential[] = [];
  
  id: number = 0;

  constructor(
    private analysisPotentialVocationService: AnalysisPotentialVocationService,
    private calculatedPotencialService: CalculatedPotencialService,
    private alertService: AlertService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
  ) { 
    this.id = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.submitProjectObj.potenciais.length > 0 && this.shouldLoad) {
      // this.progressiveLoad();
      this.list = this.submitProjectObj.potenciais;
      this.shouldLoad = false;
    }
  }

  // async progressiveLoad(): Promise<void> {
  //   const loadNum: number = 10;

  //   let forCount: number = 0;
  //   for (let i = 0; i < this.submitProjectObj.potenciais.length; i++) {
  //     if (forCount <= loadNum) {
  //       forCount++;
  //       this.list.push(this.submitProjectObj.potenciais[i]);
  //     } else {
  //       forCount = 0;
  //       await this.utilService.delay(1000);
  //     }
  //   }
  // }

  togglePotential(index: number, data: Potential): void {
    this.analysisPotentialVocationService.isLoading = true;
    
    if (!this.updateOnCheck) {
      this.submitProjectObj.potenciais[index].checado = !this.submitProjectObj.potenciais[index].checado;
    } else {

      for (let i = 0; i < this.submitProjectObj.potenciais.length; i++) {
        if (data.id === this.submitProjectObj.potenciais[i].id) {
          this.submitProjectObj.potenciais[i].checado = !this.submitProjectObj.potenciais[i].checado;
        }
      }

      this.calculatedPotencialService.create(this.submitProjectObj.potenciais, undefined, this.submitProjectObj.id).subscribe((res: Potential[]) => {
        this.alertService.success('Potencial atualizado com sucesso');
        this.analysisPotentialVocationService.getGraphData(this.id);
      })

    }
  }

}
