export class AmeiConfig {
    authorization_endpoint: string | null;
    token_endpoint: string | null;
    userinfo_endpoint: string | null;
    end_session_endpoint: string | null;

    constructor() {
        this.authorization_endpoint = null;
        this.token_endpoint = null;
        this.userinfo_endpoint = null;
        this.end_session_endpoint = null;
    }
}