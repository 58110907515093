export class AmeiCode {
    code: string | null;
    refresh_token: string | null;
    // session_state: string | null;
    grant_type: 'authorization_code' | 'refresh_token';
    client_id: string | null;
    redirect_uri: string | null;

    constructor(model?: AmeiCode) {
        this.code = model ? model.code : null;
        this.refresh_token = model ? model.refresh_token : null;
        // this.session_state = model ? model.session_state : null;
        this.grant_type = model ? model.grant_type : 'authorization_code';
        this.client_id = model ? model.client_id : null;
        this.redirect_uri = model ? model.redirect_uri : null;
    }
}