import { PlanoSolucao } from './../../../../shared/models/plano-solucao.model';
import { SectorsService } from './../../../../services/sectors/sectors.service';
import { ActionPlanService } from './../../../../services/action-plan/action-plan.service';
import { Sector } from './../../../../shared/models/sector.model';
import { PlanoAcao } from './../../../../shared/models/plano-acao.model';
import { Project } from './../../../../shared/models/project.model';
import { Member } from './../../../../shared/models/member.model';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { AcaoDTOs } from 'src/app/shared/models/acaoDTOs.model';

@Component({
  selector: 'app-planos-acao-project-public',
  templateUrl: './planos-acao-project-public.component.html',
  styleUrls: ['./planos-acao-project-public.component.scss']
})
export class PlanosAcaoProjectPublicComponent implements OnInit {
  @Input() memberList: Member[] = [];
  @Input() project = new Project();

  id: number = 0;

  planosList: PlanoAcao[] = [];
  planosGeralList: PlanoAcao[] = [];
  planoSetorialList: PlanoAcao[] = [];
  dataSubscription: Subscription = new Subscription();
  dataAtual: any = new Date();

  sectorList: Sector[] = [];

  isLoadingPlanoAcao: boolean = true;
  isLoadingPlanoSetorial: boolean = true;

  get PlanType() {
    return this.PlanType;
  }

  constructor(
    private actionPlanService: ActionPlanService,
    private activatedRoute: ActivatedRoute,
    private sectorService: SectorsService) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params.id;

    this.getPlanoAcao();
  }

  getPlanoAcao(): void {
    this.dataSubscription = this.actionPlanService
      .listAllByProjeto(this.id)
      .subscribe((res: PlanoAcao[]) => {
        for (let i = 0; i < res.length; i++) {
          if (res[i].planoSolucaoDTOs == null) {
            res[i].planoSolucaoDTOs = [];
          }
          if (res[i].planoSolucaoAdicionalDTOs == null) {
            res[i].planoSolucaoAdicionalDTOs = [];
          }

          res[i].acoes = res[i].planoSolucaoDTOs.concat(
            res[i].planoSolucaoAdicionalDTOs
          );

          for (let j = 0; j < res[i].acoes.length; j++) {
            if (res[i].acoes[j].acaoDTOs) {
              for (let k = 0; k < res[i].acoes[j].acaoDTOs.length; k++) {
                res[i].acoes[j].acaoDTOs[k] = new AcaoDTOs(
                  res[i].acoes[j].acaoDTOs[k]
                );
              }
            }
          }

          this.planosList.push(res[i]);
        }

        for (let i = 0; i < this.planosList.length; i++) {
            for (let j = 0; j < this.planosList[i].planoSolucaoAdicionalDTOs.length; j++) {

              if(!this.planosList[i].planoSolucaoDTOs.find((x: PlanoSolucao)=> x.idPlanoSolucaoAdicional === this.planosList[i].planoSolucaoAdicionalDTOs[j].id)){
                let planoSolucao: PlanoSolucao = new PlanoSolucao();
                this.planosList[i].planoSolucaoDTOs.push(planoSolucao.toPlanoSolucaoAdicional(this.planosList[i].planoSolucaoAdicionalDTOs[j]))
              }

            }

          if(this.planosList[i].idSetorSegmento){
            this.sectorService.findPublicById(this.planosList[i].idProjeto).subscribe((res: Sector[]) => {
              this.planosList.find((p: PlanoAcao) => p.id === this.planosList[i].id)['nomeSetor'] = res.find((r: Sector) => r.id === this.planosList[i].idSetorSegmento).segmento.descricao;
            });
          }
        }

        for(let i = 0; i < this.planosList.length; i++){
          let plano = this.planosList[i];
          if(plano.tipo == "PLANO_GERAL"){
            this.planosGeralList.push(plano);
          }else{
            this.planoSetorialList.push(plano);
          }
        }

        this.isLoadingPlanoAcao = false;
        this.isLoadingPlanoSetorial = false;
      });
  }

  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
  }

  calculeDate(date: any): boolean {
    if (!date) return false;
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1)
    let dataAutal = new Date(this.dataAtual.toDateString())
    return newDate < dataAutal;
  }

  dataFimTratado(date: any) {
    let newDate = new Date(date);
    return newDate.setDate(newDate.getDate() + 1)
  }
}
