import { NgModule } from '@angular/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { ProjectVersionsModalComponent } from 'src/app/shared/components/modals/projects/project-versions-modal/project-versions-modal.component';
import { MatQuill } from '../components/mat-quill/mat-quill';
import { ProjectCreateConfiguracaoComponent } from './../components/modals/projects/project-create-modal/project-create-configuracao/project-create-configuracao.component';
import { ProjectUpdateModalComponent } from './../components/modals/projects/project-update-modal/project-update-modal.component';
import { PotencialTableComponent } from './../components/potencial-table/potencial-table.component';
import { VocacaoTableComponent } from './../components/vocacao-table/vocacao-table.component';
import { BaseModule } from './base/base.module';




@NgModule({
    declarations: [
        ProjectUpdateModalComponent,
        ProjectCreateConfiguracaoComponent,
        
        PotencialTableComponent,
        VocacaoTableComponent,
        MatQuill,
        ProjectVersionsModalComponent,
    ],
    imports: [
        NgxMatSelectSearchModule,
        BaseModule,
        QuillModule.forRoot(),
        ToastrModule.forRoot(),
    ],
    exports: [
        BaseModule,

        ProjectUpdateModalComponent,
        ProjectCreateConfiguracaoComponent,
        NgxMatSelectSearchModule,

        PotencialTableComponent,
        VocacaoTableComponent,
        MatQuill,
    ]
})
export class ProjectSharedModule { }

