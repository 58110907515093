export class Analysis {
    potenciais: {
        sid: number,
        descricao: string
    }[];
    vocacoes: LocalVocacacoes[];

    constructor() {
        this.potenciais = [];
        this.vocacoes = [];
    }
}

export class LocalVocacacoes {
    sid: number;
    descricao: string;
    cruzamento: Cruzamento[];
}

export class Cruzamento {
    id: number;
    alavancaInovacaoLocalidade: boolean;
    alavancaInovacaoConhecimento: boolean;
    idPotencial: number;
    idVocacao: number;

    vocacaoName?: string;
    potencialName?: string;

    potencialCalculado?: any;
    vocacaoCalculado?: any;
}