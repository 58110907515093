import { BaseService } from './../base/base.service';
import { UtilService } from './../util.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculatedVocationService extends BaseService<any>{
  serviceName: string = "vocacao-calculado";

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  getByIdPublic(id: number){
    return this.http.get<any>(this.urlApi + "public/" + this.serviceName + '/' + id);
  }
}
