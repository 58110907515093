import { Paginate } from 'src/app/shared/models/paginate.model';
import { SegmentsService } from '../../../../services/segments/segments.service';
import { SubvertenteService } from '../../../../services/subvertente/subvertente.service';
import { VertenteService } from 'src/app/services/vertente/vertente.service';
import { UtilService } from 'src/app/services/util.service';
import { MunicipioService } from 'src/app/services/regiao/municipio/municipio.service';
import { TerritorioService } from 'src/app/services/regiao/territorio/territorio.service';
import { RegionalService } from 'src/app/services/regiao/regional/regional.service';
import { EstadoService } from 'src/app/services/regiao/estado/estado.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { Project } from 'src/app/shared/models/project.model';
import { Segment } from './../../../../shared/models/segments.model';
import { Subvertente } from './../../../../shared/models/subvertente.model';
import { Vertente } from 'src/app/shared/models/vertente.model';
import { Municipio } from 'src/app/shared/models/municipio.model';
import { Territorio } from 'src/app/shared/models/territorio.model';
import { Regional } from 'src/app/shared/models/regional.model';
import { Estado } from 'src/app/shared/models/estado.model';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-catalogo-projetos-public',
  templateUrl: './catalogo-projetos-public.component.html',
  styleUrls: ['./catalogo-projetos-public.component.scss']
})
export class CatalogoProjetosPublicComponent implements OnInit {
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;

    pos = Math.ceil(pos);
    max = Math.ceil(max);

    let footerBlue = document.getElementById("footer-sebrae");
    let footerPublic = document.getElementById("footer-area-publica");
    let projectList = document.getElementById("project-list");

    if(projectList) {
      let style = window.getComputedStyle(projectList);
      let marginBottomPx = style.marginBottom;
      let numMarginBottom = parseInt(marginBottomPx.replace("px", ""));
      max = max - (numMarginBottom + 50);
    }
    
    if(footerBlue){
     max = max - footerBlue.getBoundingClientRect().height;
    }

    if(footerPublic){
      max = max - footerPublic.getBoundingClientRect().height;
    }

    if (pos >= max && this.page <= this.totalPages && (this.filterContent.stateList && this.filterContent.stateList.length > 0)) {
      this.page++;
      this.getProjects(this.filterForm, this.page);
    }
  }

  isLoading: boolean = true;

  page: number = 1;
  totalPages: number = 1;

  filterForm: FilterForm = new FilterForm();

  formSubscription: Subscription = new Subscription();
  dataSubscription: Subscription = new Subscription();

  stateSubscription: Subscription = new Subscription();
  regionalSubscription: Subscription = new Subscription();
  territorySubscription: Subscription = new Subscription();
  municipioSubscription: Subscription = new Subscription();
  maturidadesSubscription: Subscription = new Subscription();
  vertentesSubscription: Subscription = new Subscription();
  subVertentesSubscription: Subscription = new Subscription();
  segmentsSubscription: Subscription = new Subscription();


  filterContent:
    {
      stateList: Estado[],
      regionalList: Regional[],
      territoryList: Territorio[],
      municipioList: Municipio[],
      estagioMaturidade: any[],
      ano: number,
      vertentes: Vertente[],
      subvertentes: Subvertente[],
      segmentos: Segment[]
    } =
    {
      stateList: [],
      regionalList: [],
      territoryList: [],
      municipioList: [],
      estagioMaturidade: [],
      ano: 0,
      vertentes: [],
      subvertentes: [],
      segmentos: []
    }

  projects: Project[] = [];

  form: FormGroup = new FormGroup({
    campoAberto: new FormControl(),
    estados: new FormControl(),
    municipios: new FormControl(),
    regionais: new FormControl(),
    territorios: new FormControl(),
    estagioMaturidade: new FormControl(),
    ano: new FormControl(),
    vertentes: new FormControl(),
    subvertentes: new FormControl(),
    segmentos: new FormControl()
  });

  estadoSelecionado: any;
  estadoSelecionadoCaller: any;
  constructor(
    private dialog: MatDialog,
    private projectsService: ProjectsService,
    private estadoService: EstadoService,
    private regionalService: RegionalService,
    private territorioService: TerritorioService,
    private municipioService: MunicipioService,
    private utilService: UtilService,
    private router: Router,
    private vertentesService: VertenteService,
    private subvertentesService: SubvertenteService,
    private segmentosService: SegmentsService
  ) {

    try{
      this.estadoSelecionadoCaller = this.router.getCurrentNavigation().extras.state.estadoSelecionadoCaller;
    }catch(error){}

   }

  ngOnInit(): void {
    window.scroll(0,0);
    this.getStateList();
    this.getRegionalList(null);
    this.getTerritoryList(null);
    this.getMunicipioList(null);
    this.getMaturidades();
    this.getVertentes();
    this.getSegmentos();
  }

  resetarForm(){
    this.form.get('estados').reset();
    this.form.get('regionais').reset();
    this.form.get('territorios').reset();
    this.form.get('municipios').reset();
    this.form.get('estagioMaturidade').reset();
    this.form.get('ano').reset();
    this.form.get('campoAberto').reset();
    this.form.get('vertentes').reset();
    this.form.get('subvertentes').reset();
    this.form.get('segmentos').reset();

    this.filterContent.subvertentes = [];
    this.estadoSelecionadoCaller = null;
    this.getStateList();
    this.getRegionalList(null);
    this.getTerritoryList(null);
    this.getMunicipioList(null);
    this.getMaturidades();
    this.getVertentes();
    this.getSegmentos();
  }

  getStateList(): void {
    this.filterContent.stateList = [];
    this.form.get('estados').reset();
    this.form.get('regionais').reset();
    this.form.get('territorios').reset();
    this.form.get('municipios').reset();

    this.estadoService.listarEstados().subscribe((estados: Estado[]) => {
      this.filterContent.stateList = this.utilService.sortByProp(estados, "descricao");
      if(this.estadoSelecionadoCaller){
        this.filterContent.stateList.forEach(estado => {
          if(estado.uf == this.estadoSelecionadoCaller.uf){
            this.estadoSelecionado = estado;
            this.watchFilterForm();
            return;
          }
        });
      } else{
        this.estadoSelecionado = null;
        this.watchFilterForm();
      }
    }, (error) => {});
}

  getRegionalList(e: { state: MatSelect, value: number }): void {
    this.filterContent.regionalList = [];
    this.form.get('regionais').reset();
    this.form.get('territorios').reset();
    this.form.get('municipios').reset();

    let filtro = (e?.value) ? {estados: [e.value]} : {};

    this.regionalSubscription = this.regionalService.listarPublic(filtro).subscribe(
      (regionais: Regional[]) => {
        this.filterContent.regionalList = this.utilService.sortByProp(regionais, "descricao");
      }, (error) => {

      }
    );
}

  getTerritoryList(e: { state: MatSelect, value: number }): void {
    this.filterContent.territoryList = [];
    this.form.get('territorios').reset();
    this.form.get('municipios').reset();

    this.territorySubscription = this.territorioService.listarPublic({ regionais: e?.value ? [e.value] : null }).subscribe(
      (territorios: Territorio[]) => {
        this.filterContent.territoryList = this.utilService.sortByProp(territorios, "descricao");
      }, (error) => {

      }
    );
}

  getMunicipioList(e: { state: MatSelect, value: number }): void {
    this.filterContent.municipioList = [];
    this.form.get('municipios').reset();

    this.municipioSubscription = this.municipioService.listarPublic({ territorios: e?.value ? [e.value] : null }).subscribe(
      (municipios: Municipio[]) => {
        this.filterContent.municipioList = this.utilService.sortByProp(municipios, "descricao");
      }, (error) => {

      }
    );
  }

  getMaturidades(): void{
    this.filterContent.estagioMaturidade = [];
    this.form.get("estagioMaturidade").reset();

    this.filterContent.estagioMaturidade = [
      {
        estagio: "Inicial",
        label: "Inicial"
      },
      {
        estagio: "Em Estruturação",
        label: "Em Estruturação"
      },
      {
        estagio: "Em Desenvolvimento",
        label: "Em Desenvolvimento"
      },
      {
        estagio: "Consolidado",
        label: "Consolidado"
      }
    ];
  }

  getMunicipioEstado(e: { state: MatSelect, value: Estado }): void {
    this.form.get('municipios').reset();

    let filtro = (e?.value) ? {estados: [e.value]} : {};

    this.municipioSubscription = this.municipioService.listarPublic(filtro).subscribe(
      (municipios: Municipio[]) => {
        this.filterContent.municipioList = this.utilService.sortByProp(municipios, "descricao");
      }, (error) => {

      }
    );
  }

  getTerritoryEstado(e: { state: MatSelect, value: Estado }): void {
    this.form.get('territorios').reset();

    let filtro = (e?.value) ? {estados: [e.value]} : {};

    this.territorySubscription = this.territorioService.listarPublic(filtro).subscribe(
      (territorios: Territorio[]) => {
        this.filterContent.territoryList = this.utilService.sortByProp(territorios, "descricao");
      }, (error) => {

      }
    );
  }

  getProjects(filterForm: FilterForm, page?: number): void {
    this.isLoading = true;
    let paginate = {
      page: page ? page : 1,
      size: 10
    }

    if (paginate.page === 1) {
      this.page = 1;
      this.projects = [];
    }

    let filterObj = {
      campoAberto: filterForm.campoAberto,
      estados: filterForm.estados ? [filterForm.estados] : null,
      municipios: filterForm.municipios ? [filterForm.municipios] : null,
      regionais: filterForm.regionais ? [filterForm.regionais] : null,
      territorios: filterForm.territorios ? [filterForm.territorios] : null,
      estagioMaturidade: filterForm.estagioMaturidade ? filterForm.estagioMaturidade : null,
      dataAnoRef: filterForm.ano ? filterForm.ano : null,
      idVertente: filterForm.vertentes ? filterForm.vertentes.id : null,
      idSubvertente: filterForm.subvertentes ? filterForm.subvertentes.id : null,
      idSegmento: filterForm.segmentos ? filterForm.segmentos.id : null
    }

    this.dataSubscription = this.projectsService.listarPublicPaginado(filterObj, paginate.page, paginate.size).subscribe(
      (res: Paginate<Project>) => {
        this.totalPages = res.pages;

        for (let i = 0; i < res.list.length; i++) {
          this.projects.push(res.list[i]);
        }
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      })
  }

  getVertentes(): void{
    this.filterContent.vertentes = [];
    this.form.get("vertentes").reset();

    this.vertentesSubscription = this.vertentesService.listAllPublic().subscribe(
      (res: Vertente[]) => {
      this.filterContent.vertentes = res;
    });
  }

  getSubvertentes(e: { state: MatSelect, value: Vertente }): void{
    this.filterContent.subvertentes = [];
    this.form.get("subvertentes").reset();

    this.subVertentesSubscription = this.subvertentesService.listAllPublicByVertente(e.value.id).subscribe(
      (res: Subvertente[]) => {
      this.filterContent.subvertentes = res;
    });
  }

  getSegmentos(): void{
    this.filterContent.segmentos = [];
    this.form.get("segmentos").reset();

    this.segmentsSubscription = this.segmentosService.listAllPublic().subscribe(
      (res: Segment[]) => {
      this.filterContent.segmentos = res;
    });
  }

  watchFilterForm(): void {
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(200)).subscribe((res: FilterForm) => {
      this.isLoading = true;

      this.projects = [];

      this.filterForm = new FilterForm(res);
      this.getProjects(this.filterForm);
    })
  }

  ngOnDestroy(): void {
    this.resetarForm();
    this.formSubscription?.unsubscribe();
    this.dataSubscription?.unsubscribe();

    this.stateSubscription?.unsubscribe();
    this.regionalSubscription?.unsubscribe();
    this.territorySubscription?.unsubscribe();
    this.municipioSubscription?.unsubscribe();
    this.maturidadesSubscription?.unsubscribe();
    this.vertentesSubscription?.unsubscribe();
    this.subVertentesSubscription?.unsubscribe();
    this.segmentsSubscription?.unsubscribe();
  }
}

export class FilterForm {
  campoAberto: string;
  estados: Estado | null;
  municipios: Municipio | null;
  regionais: Regional | null;
  territorios: Territorio | null;
  estagioMaturidade: string | null;
  ano: number | null;
  vertentes: Vertente | null;
  subvertentes: Subvertente | null;
  segmentos: Segment | null;

  constructor(model?: FilterForm) {
    this.campoAberto = model ? model.campoAberto : '';
    this.estados = model ? model.estados : null;
    this.municipios = model ? model.municipios : null;
    this.regionais = model ? model.regionais : null;
    this.territorios = model ? model.territorios : null;
    this.estagioMaturidade = model ? model.estagioMaturidade : null;
    this.ano = model ? model.ano : null;
    this.vertentes = model ? model.vertentes : null;
    this.subvertentes = model ? model.subvertentes : null;
    this.segmentos = model ? model.segmentos : null;
  }  onInit() {
  }

}
