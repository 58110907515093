import { NotaSetor } from './../../../../../shared/models/nota-setor.model';
import { NotaVertente } from './../../../../../shared/models/nota-vertente.model';
import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from "highcharts";
import more from 'highcharts/highcharts-more';

more(Highcharts);
@Component({
  selector: 'app-spider-web-chart-public',
  templateUrl: './spider-web-chart-public.component.html',
  styleUrls: ['./spider-web-chart-public.component.scss']
})
export class SpiderWebChartPublicComponent implements OnInit {
  @Input() color: string = '#B2B2B2';
  categorias: string[] = [
    "Ambientes de Inovação",
    "Programas e Ações",
    "ICTI",
    "Políticas Públicas",
    "Capital",
    "Governança"
  ];

  classes: string[] = [
    "amb_inv",
    "prog_ac",
    "icti",
    "pol_publ",
    "cap",
    "gov"
  ];

  load: boolean = false;

  @Input() notaVertente: NotaVertente = null;
  @Input() setor: NotaSetor = null;

  /* HighChart */
  public updateFlag: boolean = true; // optional boolean

  public Highcharts: any = Highcharts; // required
  public chartConstructor: string = "chart"; // optional string, defaults to 'chart'
  public progressTracking: any = null;
  @Input() renderTo: string = '';

  constructor() {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void{
   // this.addCircle();
  }

  ngOnChanges(): void {
    let data: number[] = [];

    if (this.setor && this.setor.nome !== '') {
      data = [
        this.setor.ambienteInovacao,
        this.setor.programaAcao,
        this.setor.icti,
        this.setor.politicaPublica,
        this.setor.capital,
        this.setor.governanca
      ];
      this.initializeChart(data);
    }

    if (this.notaVertente && this.notaVertente.estagio !== '') {
      for (let i = 0; i < this.notaVertente.notasVertente.length; i++) {
        data.push(this.notaVertente.notasVertente[i].pontuacao);
      }
      this.initializeChart(data);
    }
  }

  initializeChart(data: number[]): void {
    this.progressTracking =
    {
      chart: {
        renderTo: this.renderTo,
        polar: true,
        type: "area",
        height: "100%",
        styledMode: true,

        position: {
          align: 'center'
        }
      },

      plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                borderRadius: 2,
                y: -10,
                shape: 'callout'
            }
        }
    },

      title: {
        text: null
      },

      pane: {
        size: "75%",
      },

      credits: {
        enabled: false
      },

      xAxis: {
        categories: this.formatCategories(),
        tickmarkPlacement: "on",
        lineWidth: 0,
        labels: {
          useHTML: true,
          allowHTML: true,
        }
      },

      yAxis: {
        gridLineInterpolation: "polygon",
        lineWidth: 0,
        min: 0,
        max: 5,
        minorGridLineWidth: 2,
        minorTickInterval: '1',
        minorTickPosition: 'outside',
      },

      tooltip: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },
      series: [
        {
          name: "",
          type: "area",
          data: this.formatDataDetails(data),
          pointPlacement: "on",
        },
      ],

    }; // required

    setTimeout(() => {
      this.addClassSpan(); 
      this.reordenarContadores()
    }, 500);
  }

  formatDataDetails(data: any[]){
    let formatted = [];

    for(let i = 0; i < data.length; i++){
      let element = data[i];
      let elementConverterd =  {
        y: element,
        dataLabels: {
          className: this.classes[i],
          enabled: true,
          color: "#FFFFFF",
          borderRadius: 5,
          borderColor: "#757575",
        }
      };
      formatted.push(elementConverterd);
    }
    return formatted;
  }

  formatCategories(){
    let formatted: string[] = [];
    for(let i = 0; i < this.categorias.length; i++){
      let cat = this.categorias[i];
      let classe = this.classes[i];
      formatted.push(`
      <div class="container-label-vertente container-label-vertente-${classe}">
        <div class="topic label-vertente-grafico-${classe}"></div>
        <p class="label-vertente">${cat}</p>
      </div>`);
    }
    return formatted;
  }

  addClassSpan(){
    let containersAmbInov = document.getElementsByClassName("container-label-vertente-amb_inv");
    Array.from(containersAmbInov).forEach(element => {
      let parent = element.parentElement;
      parent.classList.add("span-container-label-vertente-amb_inv");
    });

    let containersPolPubl = document.getElementsByClassName("container-label-vertente-pol_publ");
    Array.from(containersPolPubl).forEach(element => {
      let parent = element.parentElement;
      parent.classList.add("span-container-label-vertente-pol_publ");
    });
  }

  reordenarContadores(){ 
    let labelBoxesContainers = document.getElementsByClassName("highcharts-data-labels");
    for(let i = 0; i < labelBoxesContainers.length; i++){
      let labelBox = labelBoxesContainers[i];
      let parent = labelBox.parentElement;

      parent.removeChild(labelBox);
      parent.appendChild(labelBox);
    }
  }
}
