import { Conteudo } from 'src/app/shared/models/conteudo.model';
import { Observable } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/services/base/base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConteudoService extends BaseService<any>{
  serviceName: string = 'conteudo';
  static readonly TIPO_VERTENTE = "Vertentes do Ecossistema de Inovação";
  static readonly TIPO_TIMING = "Timings do projeto";
  static readonly TIPO_IMPLANTACAO = "Etapas de Implementação da metodologia em um ecossistema de inovação";
  static readonly TIPO_SOBRE_SISTEMA = "Sobre o Sistema"

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  filtrar(filtro: any): Observable<Conteudo[]> {
    return this.http.post<Conteudo[]>(this.urlApi + "public/" + this.serviceName + '/filtrado', filtro);
  }
}
