export class AmeiToken {
    access_token: string | null;
    refresh_token: string | null;
    expires_in: number;
    refresh_expires_in: number;
    token_type: string | null;
    id_token: string | null;
    "not-before-policy": number;
    "session_state": string | null;
    scope: string | null;

    constructor() {
        this.access_token = null;
        this.refresh_token = null;
        this.expires_in = 0;
        this.refresh_expires_in = 0;
        this.token_type = null;
        this.id_token = null;

        this.scope = null;
    }
}