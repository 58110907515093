import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ClarityService {

  constructor() {}

  static loadClarity(trackingID: string): void {

    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${trackingID}"); `;
        document.body.appendChild(gaScript2);
  }
}