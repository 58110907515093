import { MatDialog } from '@angular/material/dialog';
import { BaseService } from './../base/base.service';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './../util.service';
import { Injectable } from '@angular/core';
import { UserNoPermissionModalComponent } from 'src/app/shared/components/modals/users/user-noPermission-modal/user-noPermission-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService extends BaseService<any> {
  serviceName: string = 'permissoes-usuarios';

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService,
    private dialog: MatDialog
  ) {
    super(http, utilService);
  }

  openModalNoPermission(): void {
    this.dialog.open(UserNoPermissionModalComponent, {
      width: '35%',
      disableClose : true,
    })
  }
}
