import { RegioesEstadosQtdProjetosBR } from './../../../../../shared/models/regioes-estados-qtd-projetos-br.model';
import { Component, OnInit, Input } from '@angular/core';
import Chart from 'chart.js/auto'

@Component({
  selector: 'app-qtd-projetos-regioes-br',
  templateUrl: './qtd-projetos-regioes-br.component.html',
  styleUrls: ['./qtd-projetos-regioes-br.component.scss']
})
export class QtdProjetosRegioesBrComponent implements OnInit {

  @Input() totalProjRegBr: RegioesEstadosQtdProjetosBR;
  myChart: any;

  constructor() { }

  ngOnInit() {
  }
  
  ngAfterViewInit(){
    this.carregarGraficos();
  }

  gerarClasseRegiaoCustom(regiao: string){
    let classe = "tema-";
    regiao = regiao.toLocaleLowerCase();
    return classe + regiao;
  }

  carregarGraficos(){
    this.totalProjRegBr.regioesProjCount.forEach(regiao => {
      let ctx = (<HTMLCanvasElement> document.getElementById('tema-' + (regiao.regiao.toLocaleLowerCase()))).getContext('2d');
      let item = document.getElementById("tema-"+ (regiao.regiao.toLocaleLowerCase()));
      let cssItem = window.getComputedStyle(item);
      let color = cssItem.getPropertyValue("color");
      let colorOthers = color.substring(0, color.length-1)+", 0.1)";

      let labelRegiao = regiao.regiao;

      if(labelRegiao.toUpperCase() === "CENTRO-OESTE"){
        labelRegiao = "C. Oeste";
      }

      let data = {
        labels: ['Outros', labelRegiao],
        datasets: [
          {
            data: [(this.totalProjRegBr.totalProjetosBr-regiao.totalProjetos), regiao.totalProjetos],
            backgroundColor: [colorOthers, color],
            borderWidth: 0
          }
        ]
      };

      this.myChart = new Chart(ctx, {
        type: 'pie',
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            }
          }
        },
      });
    });
  }

}
