import { UserNoPermissionModalComponent } from './../../components/modals/users/user-noPermission-modal/user-noPermission-modal.component';
import { BtnIrTopoComponent } from './../../components/btn-ir-topo/btn-ir-topo.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { MaterialBaseModule } from './../../../material/material-base/material-base.module';
import { AlertService } from './../../../services/alert.service';
import { UtilService } from './../../../services/util.service';
import { UserCardComponent } from './../../../views/users/user-card/user-card.component';
import { ButtonComponent } from './../../components/button/button.component';
import { LoadingSpinnerComponent } from './../../components/loading-spinner/loading-spinner.component';
import { PaginationComponent } from './../../components/pagination/pagination.component';
import { UserListComponent } from './../../components/user-list/user-list.component';
import { UserProfileCardComponent } from './../../components/user-profile-card/user-profile-card.component';
import { AuthInterceptor } from './../../interceptors/auth.interceptor';
import { ErrorInterceptor } from './../../interceptors/error.interceptor';


@NgModule({
  declarations: [
    ButtonComponent,
    PaginationComponent,
    LoadingSpinnerComponent,

    UserProfileCardComponent,
    UserListComponent,
    UserCardComponent,
    BtnIrTopoComponent,
    UserNoPermissionModalComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true,
      enableHtml: true
    }),  

    MaterialBaseModule,
    FontAwesomeModule,
    
  ],
  providers: [
    AlertService,
    UtilService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    ToastrModule,
    MaterialBaseModule,

    ButtonComponent,
    PaginationComponent,
    LoadingSpinnerComponent,

    UserProfileCardComponent,
    UserListComponent,
    UserCardComponent,

    FontAwesomeModule,
    BtnIrTopoComponent
  ]
})
export class BaseModule { }
