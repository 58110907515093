import { PlanoSolucaoAdicional } from './plano-solucao-adicional.model';
import { Solution } from './solution.model';
import { Acao } from './acao.model';
export class PlanoSolucao {
    acaoDTOs: Acao[];
    id: number;
    idPlanoAcao: number;
    idSolucao: number;
    solucaoDTO: Solution;
    idPlanoSolucaoAdicional?: number;

    toPlanoSolucaoAdicional(model: PlanoSolucaoAdicional): PlanoSolucao {
        let planoSolucao = new PlanoSolucao();
        planoSolucao.idPlanoSolucaoAdicional = model.id;
        planoSolucao.idPlanoAcao = model.idPlanoAcao;
        planoSolucao.acaoDTOs = model.acaoDTOs;
        planoSolucao.solucaoDTO = this.buildSolucaoDTO(model);

        return planoSolucao;
    }

    private buildSolucaoDTO(model: PlanoSolucaoAdicional): Solution {
        let solucaoDTO: Solution = new Solution();
        solucaoDTO.descricao = model.descricao;

        return solucaoDTO;
    }
}