import { ToastrModule } from 'ngx-toastr';
import { AuthBaseService } from './services/auth/auth-base.service';
import { RefreshTokenService } from './services/refresh-token/refresh-token.service';
import { UserPermissionService } from './services/user-permission/user-permission.service';
import { DragAndDropDirective } from './shared/directives/drag-and-drop.directive';
import { BaseModule } from './shared/modules/base/base.module';
import { AreaPublicaModule } from './views/area-publica/area-publica.module';
//core
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

//init
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './layout/main-static/footer/footer.component';
import { HeaderComponent } from './layout/main-static/header/header.component';
//components

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { GenericDeleteComponent } from './shared/components/modals/generic-delete/generic-delete.component';

registerLocaleData(ptBr)

//ng-module
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,

    DragAndDropDirective,

    GenericDeleteComponent,
  ],
  imports: [
    BaseModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AreaPublicaModule,
    ToastrModule.forRoot({
      positionClass :'toast-top-right'
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    RefreshTokenService,
    UserPermissionService,
    AuthBaseService,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
  ]
})
export class AppModule { }