import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CentroAjudaService } from 'src/app/services/administracao/centro-ajuda/centro-ajuda.service';
import { UtilService } from 'src/app/services/util.service';
import { AuthBaseService } from './../../../services/auth/auth-base.service';
import { AmeiConfig } from './../../../shared/models/amei-config.model';
import { UserAccess } from './../../../shared/models/user-access.model';
import { User } from './../../../shared/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  ameiConfig: AmeiConfig = new AmeiConfig();

  user: User = new User();
  userDataSubscription: Subscription = new Subscription();
  currentUserData: { user: User, userAccess: UserAccess[] } = { user: new User(), userAccess: [] };
  @Input() url: string;

  centroAjudaAtivo: boolean = false;

  constructor(
    private path: Router,
    private title: Title,
    private authBaseService: AuthBaseService,
    private utilService: UtilService,
    private centroAjudaService: CentroAjudaService,
    private router: Router

  ) {
    this.getAmeiConfiguration();
  }

  getAmeiConfiguration(): void {
    this.authBaseService.getAmeiConfig().subscribe((res: AmeiConfig) => {
      this.ameiConfig = res;
    })
  }

  ngOnInit() {
    this.getLoggedUser();
    this.verificaCentroAjudaAtivo();
  }

  getLoggedUser(): void {
    this.authBaseService.getLoggedUser().subscribe((res: { user: User, userAccess: UserAccess[] }) => {
      if (res.user) {
        this.user = res.user;
      }
    })
  }
  
  getUserData(): void {
    this.userDataSubscription = this.authBaseService.getLoggedUser().subscribe((res: { user: User, userAccess: UserAccess[] }) => {
      this.currentUserData = res;
    })
  }

  canView(accessRequest: any){
    this.getUserData();

    if (this.utilService.isAdmin(this.currentUserData.userAccess)) {
      return true;
    } else if(accessRequest === "adm") {
      return false;
    }

    if (
      this.utilService.isCoordenador(this.currentUserData.userAccess)
      )
    {
      return true;
    }

    return false;
  }

  verificaCentroAjudaAtivo(): void {
    this.centroAjudaService.verificaCentroAjudaAtivo().subscribe((res: any) => {
      this.centroAjudaAtivo = res;
    }, err => {
      this.centroAjudaAtivo = false;
    });
  }

  logout(): void {
    this.authBaseService.logout();
  }

  getAmeiMinhaContaUrl(){
    return this.authBaseService.getAmeiMinhaContaUrl();
  }

  irParaHome(){
    if(this.url !== "/"){
      this.router.navigate(["/"]);
    }
  }
}
