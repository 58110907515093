import { NotaSetor } from 'src/app/shared/models/nota-setor.model';
import { NotaVertente } from './../../shared/models/nota-vertente.model';
import { UtilService } from './../util.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../base/base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaturidadeService extends BaseService<any>{
  serviceName: string = "maturidade";

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  buscarNotaFinalVertente(idProjeto: number){
    return this.http.get<NotaVertente>(this.urlApi  + "public/" + this.serviceName + "/nota-final-por-vertente/" + idProjeto);
  }

  buscarNotaPorSetor(idProjeto: number){
    return this.http.get<NotaSetor[]>(this.urlApi  + "public/" + this.serviceName + "/nota-por-setor/" + idProjeto);
  }

}
