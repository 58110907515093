import { Municipio } from './../../../shared/models/municipio.model';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "src/app/services/base/base.service";
import { UtilService } from "src/app/services/util.service";


@Injectable({
  providedIn: 'root'
})
export class MunicipioService extends BaseService<any>{
  serviceName: string = 'v2/municipios';

  constructor(
    protected http: HttpClient,
    protected utilService: UtilService
  ) {
    super(http, utilService);
  }

  deletarMunicipio(idMunicipio: number) {
    return this.http.delete(this.urlApi + this.serviceName + '/deletarMunicipio' + this.utilService.paramsToQueryString({ idMunicipio: idMunicipio }));
  }

  listarPublic(params: any) {
    return this.http.post<Municipio[]>(this.urlApi + "public/" + this.serviceName, params);
  }

}
