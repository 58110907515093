import { EPermissionType } from "../../enums/EPermissionType";

export class Permission {
    id: number | null;
    descricao: string | null;
    tipo: EPermissionType | null;
    porEstado: boolean

    constructor(model?: Permission) {
        this.id = model ? model.id : null;
        this.descricao = model ? model.descricao : null;
        this.tipo = model ? model.tipo : null;
        this.porEstado = model ? model.porEstado : true;
    }
}