import { environment } from './../../../../environments/environment';
import { UtilService } from './../../../services/util.service';
import { UserAccess } from './../../../shared/models/user-access.model';
import { User } from './../../../shared/models/user.model';
import { UserDeleteModalComponent } from './../../../shared/components/modals/users/user-delete-modal/user-delete-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UserUpdateModalComponent } from './../../../shared/components/modals/users/user-update-modal/user-update-modal.component';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() isAddingMemberInProjectMode: boolean = false;
  @Input() isSelected: boolean = false;

  storageUrl = environment.api_url + 'public/';

  @Input() user: User = new User();
  @Input() currentUserData: { user: User, userAccess: UserAccess[] } = { user: new User(), userAccess: [] };
  @Output() shouldUpdateListEmitter: EventEmitter<boolean> = new EventEmitter();

  @Output() isSelectedEmitter: EventEmitter<User> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    if(this.user.sub && this.user.sub != "") {
      this.user.sub = this.storageUrl + 'usuarios/' + this.user.sub + '/avatar';
    }
  }

  useDefaultPic(): void {
    this.user.sub = this.utilService.useDefaultProfilePic();
  }

  openUpdateUserModal(): void {
    let updateUserDialog = this.dialog.open(UserUpdateModalComponent, {
      width: '35%',
      panelClass: 'scrollable',
      data: this.user
    });

    updateUserDialog.afterClosed().subscribe((res: { shouldUpdateList: boolean }) => {
      if (res && res.shouldUpdateList) {
        this.shouldUpdateListEmitter.emit(res.shouldUpdateList);
      }
    })
  }

  havePermission(): boolean {
    if (this.utilService.isAdmin(this.currentUserData.userAccess) || this.utilService.isCoordenador(this.currentUserData.userAccess)) {
      return true;
    }

    return false;
  }

  openDeleteUserModal(): void {
    let deleteUserDialog = this.dialog.open(UserDeleteModalComponent, {
      width: '25%',
      data: this.user
    });

    deleteUserDialog.afterClosed().subscribe((res: { shouldUpdateList: boolean }) => {
      if (res && res.shouldUpdateList) {
        this.shouldUpdateListEmitter.emit(res.shouldUpdateList);
      }
    })
  }

  selected(): void {
    this.isSelectedEmitter.emit(this.user);
  }
}
