import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalysisPotentialVocationService } from 'src/app/services/analysis-potential-vocation/analysis-potential-vocation.service';
import { AlertService } from './../../../services/alert.service';
import { CalculatedVocationService } from './../../../services/calculated-vocation/calculated-vocation.service';
import { UtilService } from './../../../services/util.service';
import { Project } from './../../models/project.model';
import { Vocation } from './../../models/vocacao.model';

@Component({
  selector: 'app-vocacao-table',
  templateUrl: './vocacao-table.component.html',
  styleUrls: ['./vocacao-table.component.scss']
})
export class VocacaoTableComponent implements OnInit {

  @Input() submitProjectObj: Project = new Project(null);
  @Input() updateOnCheck: boolean = false;
  @Input() canChange: boolean = false;
  @Input() loadListTrigger: boolean = false;

  selectedVocation: Vocation[] = [];

  shouldLoad: boolean = true;

  list: Vocation[] = [];

  id: number = 0;

  constructor(
    private analysisPotentialVocationService: AnalysisPotentialVocationService,
    private calculatedVocationService: CalculatedVocationService,
    private alertService: AlertService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    
  ) { 
    this.id = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.submitProjectObj.vocacoes.length > 0 && this.shouldLoad) {
      this.list = this.submitProjectObj.vocacoes;

      this.shouldLoad = false;
    }
  }

  // async progressiveLoad(): Promise<void> {
  //   const loadNum: number = 5;

  //   let forCount: number = 0;
  //   for (let i = 0; i < this.submitProjectObj.vocacoes.length; i++) {
  //     if (forCount <= loadNum) {
  //       forCount++;
  //       this.list.push(this.submitProjectObj.vocacoes[i]);
  //     } else {
  //       forCount = 0;
  //       await this.utilService.delay(1000);
  //     }
  //   }
  // }

  toggleVocation(index: number, data: Vocation): void {
    this.analysisPotentialVocationService.isLoading = true;
    
    if (!this.updateOnCheck) {
      this.submitProjectObj.vocacoes[index].checado = !this.submitProjectObj.vocacoes[index].checado;
    } else {

      for (let i = 0; i < this.submitProjectObj.vocacoes.length; i++) {
        if (data.id === this.submitProjectObj.vocacoes[i].id) {
          this.submitProjectObj.vocacoes[i].checado = !this.submitProjectObj.vocacoes[i].checado;
        }
      }

      this.calculatedVocationService.create(this.submitProjectObj.vocacoes, undefined, this.submitProjectObj.id).subscribe((res: Vocation[]) => {
        this.alertService.success('Vocação atualizado com sucesso');
        this.analysisPotentialVocationService.getGraphData(this.id);
      })

    }
  }

  trackByFn(index, item) {
    return index;
  }
}
