import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() txt: string = '';
  @Input() class: string = 'border-blue';
  @Input() isLoading: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() customStyle: string = '';

  @Input() iconPosition: 'left' | 'right' | 'both' | null = null;
  @Input() icon: string = '';

  constructor() { }


  ngOnInit(): void {
  }

}
